import Cookie from "js-cookie"
import { fetchUser, setInitialLoading } from "redux/actions"

export const getCaregiverFromCookieOrSessionStorage = (dispatch) => {
  const caregiverCookie = Cookie.get("caregiver")
  const caregiver = caregiverCookie ? JSON.parse(caregiverCookie) : null
  if (caregiver) {
    dispatch(fetchUser(caregiver))
    sessionStorage.setItem("caregiver", caregiverCookie)
    return caregiver
  }
  const sessionStorageCaregiver = sessionStorage.getItem("caregiver")
  if (sessionStorageCaregiver) {
    const parsedSessionStorageCaregiver = JSON.parse(sessionStorageCaregiver)
    dispatch(fetchUser(parsedSessionStorageCaregiver))
    return parsedSessionStorageCaregiver
  }
  dispatch(setInitialLoading(false))
  return null
}
