/* eslint-disable no-nested-ternary */
import React from "react"
import { format } from "date-fns"
import { Typography, Button, useMediaQuery } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Table } from "components/Table"
import config from "config"
import { useTheme } from "@mui/material/styles"
import { sessionType } from "utils/sessionType"

const useStyles = makeStyles((theme) => ({
  tableCell: {
    color: theme.palette.black,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  tableCellMinWidth: {
    minWidth: "130px",
  },
  sortIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  emptyText: {
    color: theme.palette.black,
  },
  tableCellButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    "& p": { fontSize: "12px" },
  },
}))

const getColumns = ({ classes, isWidthMdDown, onOpenChatClick }) => [
  {
    id: "patient",
    align: "left",
    sortable: true,
    label: "Patient",
    mobileTitle: true,
    formatBodyCell({ value }) {
      return (
        <Typography variant="body2" className={classes.tableCell}>
          {value}
        </Typography>
      )
    },
  },
  {
    id: "userId",
    align: "right",
    sortable: true,
    label: "ID",
    formatBodyCell({ value }) {
      return (
        <Typography variant="body2" className={classes.tableCell}>
          {value.slice(0, 5)}
        </Typography>
      )
    },
  },
  {
    id: "sessionNumber",
    sortable: false,
    align: "right",
    label: "Session",
    formatBodyCell({ value }) {
      return (
        <Typography variant="body2" className={classes.tableCell}>
          {value}
        </Typography>
      )
    },
  },
  {
    id: "startedAt",
    sortable: true,
    align: "right",
    label: "Tid",
    formatBodyCell({ value }) {
      return (
        <Typography
          variant="body2"
          className={[classes.tableCell, classes.tableCellMinWidth]}
        >
          {value ? format(new Date(value), "d MMM HH:mm") : "-"}
        </Typography>
      )
    },
  },
  {
    id: "sessionType",
    sortable: false,
    align: isWidthMdDown ? "right" : "center",
    label: "Besökstyp",
    formatBodyCell({ value }) {
      return (
        <Typography
          variant="body2"
          className={classes.tableCell}
          sx={{ textTransform: "uppercase" }}
        >
          {Object.keys(sessionType).find((key) => sessionType[key] === value)}
        </Typography>
      )
    },
  },
  {
    id: "kaddio",
    sortable: false,
    align: "right",
    label: "Kaddio journal",
    formatBodyCell({ rowData }) {
      return (
        rowData.visitId && (
          <Button
            onClick={() =>
              window.open(
                `${config.kaddioUrl}/contacts/${rowData.visitId}`,
                "_blank"
              )
            }
            className={classes.tableCellButton}
          >
            <Typography variant="body2">Journalanteckning</Typography>
          </Button>
        )
      )
    },
  },
  {
    id: "chatId",
    sortable: false,
    align: "right",
    label: "Meddelanden",
    formatBodyCell({ rowData }) {
      return (
        <Button
          onClick={() => onOpenChatClick(rowData)}
          className={classes.tableCellButton}
        >
          <Typography variant="body2">Meddelanden</Typography>
        </Button>
      )
    },
  },
]

export const SessionTable = ({
  data = [],
  orderBy,
  order,
  setOrder,
  setOrderBy,
  emptyText,
  handleOpenChatPreview,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isWidthMdDown = useMediaQuery(theme.breakpoints.down("md"))

  const handleSortChange = (id) => {
    const isAsc = orderBy === id && order === "asc"

    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(id)
  }

  const columns = getColumns({
    classes,
    isWidthMdDown,
    onOpenChatClick: handleOpenChatPreview,
  })

  return (
    <Table
      data={data}
      columns={columns}
      order={order}
      orderBy={orderBy}
      emptyText={emptyText}
      onSortChange={handleSortChange}
    />
  )
}
