import { useController } from "react-hook-form"
import {
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1.25),
    display: "inline-flex",
  },
  label: {
    background: theme.palette.white,
    minWidth: "90px",
    textAlign: "center",
  },
  select: { width: "230px" },
}))

const Select = ({
  data,
  name,
  controller,
  label,
  labelId,
  disabled,
  availabilityTypeSelect,
}) => {
  const classes = useStyles()
  const { field, fieldState } = useController({
    name,
    control: controller,
  })
  const { onChange, ...rest } = field || {}
  const error = fieldState?.error
  const helperText = error?.message
  const invalid = !!error?.message

  return (
    <FormControl className={classes.root} variant="outlined">
      <InputLabel id={labelId} className={classes.label}>
        {label}
      </InputLabel>
      <MuiSelect
        {...rest}
        labelId={labelId}
        className={classes.select}
        disabled={disabled}
        onChange={(newValue) => onChange(newValue)}
        {...field}
      >
        {availabilityTypeSelect
          ? data.map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {key}
              </MenuItem>
            ))
          : data.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
      </MuiSelect>
      {invalid && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Select
