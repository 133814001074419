import { css } from "@emotion/react"
import { FiberManualRecord } from "@mui/icons-material"
import { keyframes, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useEffect, useRef, useState } from "react"

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }`

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  recordIcon: {
    color: theme.palette.error.main,
  },
}))

const Timer = ({ duration }) => {
  const classes = useStyles()
  const animatedTimer = css`
    animation: ${fade} 1000ms;
  `
  const [timer, setTimer] = useState(duration)
  const id = useRef(null)
  const clear = () => {
    window.clearInterval(id.current)
  }
  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1)
    }, 1000)
    return () => clear()
  }, [])

  useEffect(() => {
    if (timer === 0) {
      clear()
    }
  }, [timer])
  return (
    <Typography css={animatedTimer} className={classes.root} alignSelf="center">
      <FiberManualRecord
        sx={{ animation: `${fade} 1.5s infinite ease` }}
        className={classes.recordIcon}
      />
      00:{timer.toString().padStart(2, "0")}
    </Typography>
  )
}

export default Timer
