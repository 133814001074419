import Joi from "@hapi/joi"

const profileValidation = (data) =>
  Joi.object({
    shortDesc: Joi.string()
      .required()
      .error((errors) =>
        errors.map((err) => {
          err.message = "Vänligen fyll i en kort beskrivning"
          return err
        })
      ),
    longDesc: Joi.string()
      .required()
      .error((errors) =>
        errors.map((err) => {
          err.message = "Vänligen fyll i en lång beskrivning"
          return err
        })
      ),
    expertise: Joi.array()
      .items(Joi.string().required())
      .error((errors) =>
        errors.map((err) => {
          err.message = "Välj minst ett alternativ"
          return err
        })
      ),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .pattern(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      .required()
      .error((errors) =>
        errors.map((err) => {
          err.message = "Vänligen fyll i giltig e-post"
          return err
        })
      ),
    phoneNumber: Joi.string()
      .min(6)
      .regex(/^([+])?(\d+)$/)
      .allow("")
      .error((errors) =>
        errors.map((err) => {
          err.message = "Vänligen fyll i giltigt telefonnummer"
          return err
        })
      ),
    maxPatients: Joi.number()
      .required()
      .greater(0)
      .less(51)
      .error((errors) =>
        errors.map((err) => {
          err.message = "Du kan ha från 1 till 50 patienter"
          return err
        })
      ),
    activeInMatchmaking: Joi.boolean()
      .required()
      .error((errors) =>
        errors.map((err) => {
          err.message = "Error"
          return err
        })
      ),
  }).validate(data, {
    abortEarly: false,
  })

export default profileValidation
