import React from "react"
import { Controller } from "react-hook-form"
import { TextField } from "@mui/material"

const Input = (props) => {
  const { errors, name, defaultValue, control, ...rest } = props

  const error = !!errors?.[name]
  const helperText = error && errors[name].message

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...rest}
          inputRef={field.ref}
          helperText={helperText}
          error={error}
        />
      )}
    />
  )
}

export default Input
