export const generateTimeSlots = (start, end) => {
  const slotDuration = 30
  const timeSlots = []
  let startTime = new Date(start)

  while (startTime < end) {
    const nextTime = new Date(startTime)
    nextTime.setMinutes(startTime.getMinutes() + slotDuration)

    if (nextTime > end) {
      nextTime.setTime(end.getTime())
    }

    timeSlots.push({
      start: startTime,
      end: nextTime,
    })

    startTime = new Date(nextTime)
  }

  return timeSlots
}
