import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  fetchActiveChats,
  fetchChatEntries,
  resetActiveChat,
  fetchKaddioForms,
  fetchSessions,
} from "redux/actions"
import {
  Box,
  Hidden,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import SideBar from "pages/chat/components/SideBar"
import ChatContainer from "pages/chat/components/ChatContainer"
import ChatBar from "pages/chat/components/ChatBar"
import PatientInformation from "pages/chat/components/PatientInformation"
import { ErrorOutline as ErrorOutlineIcon } from "@mui/icons-material/"
import CameraDrawer from "./components/CameraDrawer"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  noPhone: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    textAlign: "center",
  },
  content: {
    boxSizing: "border-box",
    flex: 2,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      borderRight: `0.5px solid ${theme.palette.gray}`,
      borderBottom: `0.5px solid ${theme.palette.gray}`,
    },
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  errorButton: {
    marginTop: theme.spacing(2),
  },
  icon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
  },
}))

const Chat = ({ dispatch, caregiverId, chats, kaddioForms }) => {
  const classes = useStyles()
  const {
    loading,
    inactiveInstancesLoading,
    error,
    activeInstances,
    inactiveInstances,
    activeUnreadMessagesCount,
    inactiveUnreadMessagesCount,
    activeChat,
    switchChatLoading,
  } = chats
  const [isCameraOpen, setCameraOpen] = useState(false)

  useEffect(() => {
    if (activeChat?.chatId) {
      dispatch(fetchChatEntries(activeChat.chatId))
    }
  }, [activeChat?.chatId, dispatch])

  useEffect(() => {
    dispatch(fetchActiveChats())
    dispatch(fetchSessions(caregiverId))

    if (!kaddioForms) {
      dispatch(fetchKaddioForms())
    }

    return () => {
      dispatch(resetActiveChat())
    }

    // disables warning about missing dependency for kaddioForms
    // eslint-disable-next-line
  }, [dispatch])

  const renderLoading = () => (
    <Box className={classes.loading}>
      <CircularProgress size={50} color="primary" />
    </Box>
  )

  if (loading) {
    return renderLoading()
  }

  if (error) {
    return (
      <Box className={classes.loading}>
        <ErrorOutlineIcon color="primary" className={classes.icon} />
        <Typography variant="h4">Något gick fel!</Typography>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(fetchActiveChats())
            dispatch(fetchSessions(caregiverId))
          }}
          className={classes.errorButton}
        >
          Försök igen
        </Button>
      </Box>
    )
  }

  const onBackClick = () => {
    dispatch(resetActiveChat())
  }

  const renderChat = () => (
    <Box className={classes.content}>
      {switchChatLoading ? (
        renderLoading()
      ) : (
        <>
          <PatientInformation onBackClick={onBackClick} />
          <ChatContainer active={!!activeChat?.chatId} />
          {isCameraOpen && (
            <CameraDrawer
              isCameraOpen={isCameraOpen}
              setCameraOpen={setCameraOpen}
            />
          )}
        </>
      )}

      <ChatBar
        setCameraOpen={setCameraOpen}
        switchChatLoading={switchChatLoading}
      />
    </Box>
  )

  const renderSideBar = () => (
    <SideBar
      activeInstances={activeInstances}
      inactiveInstances={inactiveInstances}
      activeUnreadMessagesCount={activeUnreadMessagesCount}
      inactiveUnreadMessagesCount={inactiveUnreadMessagesCount}
      activeChat={activeChat}
      switchChatLoading={switchChatLoading}
      inactiveInstancesLoading={inactiveInstancesLoading}
    />
  )

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        {renderSideBar()}
        {renderChat()}
      </Hidden>
      <Hidden smUp>
        {!!activeChat?.chatId ? renderChat() : renderSideBar()}
      </Hidden>
    </Box>
  )
}

const mapStateToProps = ({ user, chats, kaddioForms }) => {
  const { userId: caregiverId } = user
  return { caregiverId, chats, kaddioForms }
}

export default connect(mapStateToProps)(Chat)
