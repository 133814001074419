export const getTimeLimitStringForAddingAvailability = (
  minutesUntilBooking
) => {
  const hoursUntilBooking = Math.trunc(minutesUntilBooking / 60)
  const remainingMinutesUntilBooking = minutesUntilBooking % 60
  const availabilityTimeLimitText =
    // eslint-disable-next-line no-nested-ternary
    minutesUntilBooking < 60
      ? `${minutesUntilBooking}min`
      : remainingMinutesUntilBooking
      ? `${hoursUntilBooking}h ${remainingMinutesUntilBooking}min`
      : `${hoursUntilBooking}h`
  return availabilityTimeLimitText
}
