import { format, isToday, isTomorrow, differenceInMinutes } from "date-fns"
import { sv } from "date-fns/locale"

const timeFormat = "HH:mm"
const dateFormat = "d MMM"
const localeOptions = { locale: sv }
const oneHourInMinutes = 60

export const getFormattedDateAndTime = (date) => {
  const currentTime = new Date()
  const dateTime = format(date, timeFormat, localeOptions)

  if (isToday(date)) {
    const minutesUntilDate = differenceInMinutes(date, currentTime, {
      roundingMethod: "ceil",
    })
    if (minutesUntilDate >= oneHourInMinutes) {
      return `idag kl ${dateTime}`
    }
    return `idag kl ${dateTime} (om ${minutesUntilDate} min)`
  }
  if (isTomorrow(date)) {
    return `imorgon kl ${dateTime}`
  }
  const dateDay = format(date, dateFormat, localeOptions).replace(/\./g, "")
  return `${dateDay} kl ${dateTime}`
}
