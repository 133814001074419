import Joi from "@hapi/joi"
import {
  addMonths,
  addMinutes,
  subDays,
  isToday,
  differenceInMinutes,
} from "date-fns"
import { availabilityType } from "utils/availabilityType"
import { availabilityVisibilityType } from "utils/availabilityVisibilityType"
import { getTimeLimitStringForAddingAvailability } from "utils/getTimeLimitStringForAddingAvailability"

const availabilityValidation = (
  data,
  editingAvailabilityThatStartsInPast,
  minutesUntilBooking
) =>
  Joi.object({
    availabilityDate: Joi.date()
      .greater(subDays(new Date(), 1))
      .less(addMonths(new Date(), 3))
      .required()
      .error((errors) => {
        const [error] = errors || []
        const greaterDateErrorMessage = "Datum kan inte vara före idag"
        switch (error.code) {
          case "date.greater":
            error.message = greaterDateErrorMessage
            break
          case "date.less":
            error.message =
              "Datum kan inte vara mer än tre månader framåt i tiden"
            break
          default:
            error.message = "Vänligen ange ett giltigt datum"
            break
        }
        return errors
      }),
    availabilityStartTime: Joi.date()
      .min(
        Joi.ref("availabilityDate", {
          adjust: (value) => {
            if (
              value &&
              isToday(value) &&
              !editingAvailabilityThatStartsInPast
            ) {
              return data.availabilityVisibilityType ===
                availabilityVisibilityType.OwnBooking
                ? new Date()
                : addMinutes(new Date(), minutesUntilBooking)
            }
            // setHours(0, -1, 0) is to enable putting midnight
            return new Date().setHours(0, -1, 0)
          },
        })
      )
      .required()
      .error((errors) => {
        const [error] = errors || []
        const minTimeErrorMessage =
          !minutesUntilBooking ||
          data.availabilityVisibilityType ===
            availabilityVisibilityType.OwnBooking
            ? "Starttiden måste vara i framtiden"
            : `Starttiden måste vara minst ${getTimeLimitStringForAddingAvailability(
                minutesUntilBooking
              )} i framtiden`
        switch (error.code) {
          case "date.min":
            error.message = minTimeErrorMessage
            break
          default:
            error.message = "Var vänlig ange en giltig starttid"
            break
        }
        return errors
      }),
    availabilityEndTime: Joi.date()
      .min(
        Joi.ref("availabilityDate", {
          adjust: (value) => {
            if (value && isToday(value)) {
              return new Date()
            }
            // setHours(0, 29, 0) to enable putting 00:30
            return new Date().setHours(0, 29, 0)
          },
        })
      )
      .greater(
        Joi.ref("availabilityStartTime", {
          adjust: (value) => addMinutes(value, 1),
        })
      )
      .custom((value, helpers) => {
        const validTimeSlotDuration = 30
        const { availabilityStartTime } = helpers.state.ancestors[0]
        const availabilityDurationInMinutes = differenceInMinutes(
          value,
          availabilityStartTime
        )
        if (availabilityDurationInMinutes % validTimeSlotDuration) {
          return helpers.error("any.custom")
        }

        return value
      })
      .required()
      .error((errors) => {
        const [error] = errors || []
        switch (error.code) {
          case "any.custom":
            error.message =
              "Längden på tillgängligheten behöver vara i 30 min block"
            break
          case "date.min":
            error.message = "Sluttiden måste vara i framtiden"
            break
          case "date.greater":
            error.message = "Sluttiden måste vara efter starttiden"
            break
          default:
            error.message = "Var vänlig ange en giltig sluttid"
            break
        }
        return errors
      }),
    availabilityType: Joi.number()
      .valid(...Object.values(availabilityType))
      .error((errors) =>
        errors.map((err) => {
          err.message = "Ange en giltig upprepningstyp"
          return err
        })
      ),
    availabilityVisibilityType: Joi.number()
      .valid(...Object.values(availabilityVisibilityType))
      .error((errors) =>
        errors.map((err) => {
          err.message = "Ange en giltig besökstyp"
          return err
        })
      ),
    availabilityTransform: Joi.boolean(),
    availabilityTitle: Joi.when("availabilityVisibilityType", {
      is: availabilityVisibilityType.OwnBooking,
      then: Joi.string()
        .max(50)
        .required()
        .error((errors) =>
          errors.map((err) => {
            err.message = "Rubriken får inte vara mer än 50 tecken lång"
            return err
          })
        ),
      otherwise: Joi.string().max(50).allow(null),
    }),
    availabilityDescription: Joi.string().max(1000).allow(null),
  }).validate(data, {
    abortEarly: true,
  })

export default availabilityValidation
