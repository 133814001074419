import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  sendButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: "0.8",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: "1",
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
}))

const MaterialConfirmationDialog = ({ onClose, openDialog, onSend }) => {
  const classes = useStyles()
  return (
    <Dialog open={openDialog} onClose={onClose}>
      <DialogTitle>Skicka material</DialogTitle>
      <DialogContent>
        Detta kommer nu skickas till patienten. Är du säker?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Avbryt
        </Button>
        <Button onClick={onSend} className={classes.sendButton}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MaterialConfirmationDialog
