import Joi from "@hapi/joi"
import { addMonths } from "date-fns"

const addTimeSlotValidation = (data) =>
  Joi.object({
    availabilityDate: Joi.date()
      .greater(new Date())
      .less(addMonths(new Date(), 3))
      .required()
      .error((errors) => {
        const [error] = errors || []
        switch (error.code) {
          case "date.greater":
            error.message = "Datum kan inte vara före imorgon"
            break
          case "date.less":
            error.message =
              "Datum kan inte vara mer än tre månader framåt i tiden"
            break
          default:
            error.message = "Vänligen ange ett giltigt datum"
            break
        }
        return errors
      }),
    availabilityStartTime: Joi.date()
      .required()
      .error((errors) => {
        const [error] = errors || []
        switch (error.code) {
          default:
            error.message = "Var vänlig ange en giltig starttid"
            break
        }
        return errors
      }),
  }).validate(data, {
    abortEarly: true,
  })

export default addTimeSlotValidation
