import Api from "api"

/**
 * Marks a given Ikbt Patient as motivated.
 *
 * @param {string} patientId
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const motivateIkbtPatient = (patientId) =>
  Api.put("/users/caregiver/ikbt/updatePatientMotivate", {
    patientId,
  })
