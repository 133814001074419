import { useState, useEffect } from "react"

function getSessionStorageOrDefault(key) {
  const stored = sessionStorage.getItem(key)
  if (!stored) {
    return ""
  }
  return JSON.parse(stored)
}

export function useSessionStorage(key) {
  const [value, setValue] = useState(getSessionStorageOrDefault(key))
  useEffect(() => {
    setValue(getSessionStorageOrDefault(key))
  }, [key])
  return [value, setValue]
}
