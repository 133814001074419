import React from "react"
import { connect } from "react-redux"
import { Box, Hidden, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import DefaultAvatar from "components/DefaultAvatar"
import ProfileForm from "./components/Form"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flexGrow: 1,
    backgroundColor: "rgb(246,246,246)",
  },
}))

const Profile = ({ avatarUrl, firstName, lastName }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Box mx={2} mt={2}>
          <Box ml={2}>
            <Typography color="primary" variant="h6">
              Min Profil
            </Typography>
          </Box>
        </Box>
      </Hidden>
      <Box className={classes.container} m={2} p={2}>
        <Box pb={4}>
          <DefaultAvatar
            size={75}
            src={avatarUrl}
            name={`${firstName} ${lastName}`}
          />
        </Box>
        <ProfileForm />
      </Box>
    </div>
  )
}

const mapStateToProps = ({ user }) => {
  const { avatarUrl, firstName, lastName } = user
  return { avatarUrl, firstName, lastName }
}

export default connect(mapStateToProps)(Profile)
