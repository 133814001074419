import { differenceInYears, differenceInCalendarYears } from "date-fns"

export const getUserAge = (ssn, calculateAgeBasedOnCalendarYear) => {
  const birthYear = +ssn.substring(0, 4)
  const birthMonth = +ssn.substring(4, 6)
  const birthDay = +ssn.substring(6, 8)
  const now = new Date()
  const userBirthdayDate = new Date(birthYear, birthMonth - 1, birthDay)

  let age = differenceInYears(now, userBirthdayDate)
  if (calculateAgeBasedOnCalendarYear && age < 20) {
    age = differenceInCalendarYears(now, userBirthdayDate)
  }
  return age
}
