import TagManager from "react-gtm-module"
import config from "config"
import { createGeneralLog } from "api/createGeneralLog"

const { isDev } = config

export const trackEvent = (event, additionalParams = {}) => {
  if (!isDev) {
    const eventData = {
      event,
      ...additionalParams,
    }

    TagManager.dataLayer({
      dataLayer: eventData,
    })

    if (event === "sessionCreated") {
      createGeneralLog({ data: eventData })
    }
  }
}
