import { Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { differenceInWeeks } from "date-fns"
import { ikbtStatus } from "utils/ikbtStatus"
import { Cancel, CheckCircle, Error } from "@mui/icons-material"

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    flexDirection: "column",
  },
}))

export const PatientStatus = ({ patient }) => {
  switch (patient?.patientStatus) {
    case ikbtStatus.Active:
      return <PatientStatusActive />
    case ikbtStatus.Invited:
      return <PatientStatusInvited />
    case ikbtStatus.Inactive:
      return <PatientStatusInactive lastActive={patient.lastLogin} />
    case ikbtStatus.Paused:
      return <PatientStatusPaused />
    case ikbtStatus.Completed:
      return <PatientStatusCompleted />
    default:
      return null
  }
}

export const PatientStatusActive = () => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <CheckCircle
        sx={{
          width: 35,
          height: 35,
          color: (theme) => theme.palette.primary.main,
        }}
      />
      <Typography>Aktiv</Typography>
    </Box>
  )
}

export const PatientStatusInvited = () => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <svg
        width="35"
        height="34"
        viewBox="0 0 35 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="17.5007"
          cy="16.9998"
          rx="16.7273"
          ry="16.6111"
          fill="#357376"
        />
        <path
          /* eslint-disable-next-line max-len */
          d="M23.5 11H11.5C10.675 11 10.0075 11.675 10.0075 12.5L10 21.5C10 22.325 10.675 23 11.5 23H23.5C24.325 23 25 22.325 25 21.5V12.5C25 11.675 24.325 11 23.5 11ZM23.5 14L17.5 17.75L11.5 14V12.5L17.5 16.25L23.5 12.5V14Z"
          fill="white"
        />
      </svg>
      <Typography>Inbjuden</Typography>
    </Box>
  )
}

export const PatientStatusInactive = ({ lastActive }) => {
  const classes = useStyles()
  const weeks = differenceInWeeks(new Date(), new Date(lastActive))

  return (
    <Box className={classes.container}>
      <Error
        sx={{
          width: 35,
          height: 35,
          color: (theme) => theme.palette.warning.main,
        }}
      />
      <Typography>Inaktiv {weeks <= 1 ? "1 vecka" : "2+ veckor"}</Typography>
    </Box>
  )
}

export const PatientStatusPaused = () => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <svg
        width="35"
        height="34"
        viewBox="0 0 35 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="17.5007"
          cy="16.9998"
          rx="16.7273"
          ry="16.6111"
          fill="#20649B"
        />
        <path
          /* eslint-disable-next-line max-len */
          d="M17.7901 26C16.5717 26 15.4294 25.7541 14.3633 25.2623C13.2971 24.7706 12.3688 24.1059 11.5783 23.2683C10.7878 22.4308 10.1604 21.4472 9.69625 20.3177C9.23208 19.1882 9 17.9779 9 16.687C9 14.7814 9.4968 13.0525 10.4904 11.5003C11.484 9.94818 12.8148 8.81095 14.4829 8.08866C14.686 8.01182 14.8963 7.98493 15.1139 8.00798C15.3315 8.03103 15.5128 8.10403 15.6579 8.22697C15.7739 8.33455 15.8645 8.48439 15.9298 8.67649C15.9951 8.86858 16.0277 9.11831 16.0277 9.42567C16.0567 10.6397 16.2888 11.7962 16.724 12.895C17.1591 13.9938 17.7829 14.9735 18.5951 15.8341C19.4074 16.6947 20.3358 17.3555 21.3801 17.8166C22.4245 18.2776 23.5196 18.5235 24.6655 18.5542C24.9701 18.5542 25.2022 18.5811 25.3618 18.6349C25.5213 18.6887 25.6519 18.7771 25.7534 18.9C25.8695 19.0537 25.9456 19.2535 25.9819 19.4994C26.0181 19.7452 26 19.9681 25.9275 20.1679C25.2602 21.9352 24.1869 23.349 22.7073 24.4094C21.2278 25.4698 19.5887 26 17.7901 26ZM21.8588 12.3994L20.3358 11.6617C20.1617 11.5849 20.0747 11.4466 20.0747 11.2468C20.0747 11.047 20.1617 10.9087 20.3358 10.8318L21.8588 10.0942L22.555 8.48054C22.6276 8.29613 22.7581 8.20392 22.9467 8.20392C23.1352 8.20392 23.2658 8.29613 23.3383 8.48054L24.0346 10.0942L25.5576 10.8318C25.7317 10.9087 25.8187 11.047 25.8187 11.2468C25.8187 11.4466 25.7317 11.5849 25.5576 11.6617L24.0346 12.3994L23.3383 14.013C23.2658 14.1974 23.1352 14.2896 22.9467 14.2896C22.7581 14.2896 22.6276 14.1974 22.555 14.013L21.8588 12.3994Z"
          fill="white"
        />
      </svg>
      <Typography>Pausad</Typography>
    </Box>
  )
}

export const PatientStatusCompleted = () => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Cancel
        sx={{
          width: 35,
          height: 35,
          color: (theme) => theme.palette.warning.darker,
        }}
      />
      <Typography>Avslutad</Typography>
    </Box>
  )
}
