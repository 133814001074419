import React from "react"
import { Controller } from "react-hook-form"
import {
  Select as MuiSelect,
  MenuItem,
  Chip,
  FormHelperText,
  Box,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { translateExpertise } from "utils/translateExpertise"

const useStyles = makeStyles(() => ({
  select: {
    width: "inherit",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}))

const MultiSelect = ({
  data,
  name,
  defaultValue,
  control,
  errors,
  className,
}) => {
  const classes = useStyles()
  const error = !!errors?.[name]
  const helperText = error && errors[name].message
  // remove Annat (other) and Osäker (unsure) options
  const expertisesForDisplay = data.filter(
    (expertise) => expertise.name !== "other" && expertise.name !== "unsure"
  )

  return (
    <Box className={className}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MuiSelect
            variant="outlined"
            multiple
            error={error}
            className={classes.select}
            {...field}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => {
                  const translatedValue = translateExpertise(value)
                  return (
                    <Chip
                      size="small"
                      color="primary"
                      key={value}
                      label={translatedValue}
                      className={classes.chip}
                    />
                  )
                })}
              </div>
            )}
          >
            {expertisesForDisplay.map((item) => {
              const translatedName = translateExpertise(item.name)
              return (
                <MenuItem key={item.id} value={item.name}>
                  {translatedName}
                </MenuItem>
              )
            })}
          </MuiSelect>
        )}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </Box>
  )
}

export default MultiSelect
