import {
  Popover,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  List,
  ListItem,
  Link,
  IconButton,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Close as CloseIcon } from "@mui/icons-material/"
import halsaHemmaLogoWithText from "assets/halsa_hemma_logo_with_text.png"
import config from "config"

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.light_gray,
      [theme.breakpoints.down("sm")]: {
        display: "table",
        width: "95%",
      },
    },
    "& .MuiDialogTitle-root": {
      padding: theme.spacing(2, 1, 1),
      position: "relative",
      "& .MuiTypography-subtitle2": {
        fontWeight: 600,
        marginTop: theme.spacing(1),
      },
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  content: {
    width: "600px",
    overflowY: "auto",
    overflowX: "auto",
    padding: theme.spacing(0, 3, 1, 4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      minHeight: "230px",
      paddingRight: theme.spacing(2),
    },
  },
  listHeader: {
    fontSize: "0.75rem",
    fontWeight: 500,
    padding: theme.spacing(1, 1, 1, 0.5),
  },
  listItem: {
    display: "list-item",
    fontSize: "0.75rem",
    fontWeight: 500,
    padding: theme.spacing(1, 1, 1, 0.5),
  },
  actions: {
    justifyContent: "center",
    margin: theme.spacing(1, 0, 2),
    "& .MuiButton-root": {
      borderRadius: "50px",
      textTransform: "none",
      padding: theme.spacing(1, 5),
      "&:disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        boxShadow: "none",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        pointerEvents: "none",
      },
    },
  },
}))

const HalsaHemmaDialog = ({
  openDialog,
  anchorEl,
  onClose,
  onRegisterPatientForHalsaHemma,
}) => {
  const classes = useStyles()
  const { halsaHemmaAppUrl } = config

  return (
    <Popover
      open={openDialog}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      className={classes.contentContainer}
    >
      <DialogTitle>
        <IconButton
          onClick={() => {
            onClose()
          }}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
        <img
          alt="Hälsa Hemma logo"
          src={halsaHemmaLogoWithText}
          width="150px"
        />
        <Typography variant="subtitle2">
          Hänvisning av patient till Hälsa Hemmas vårdcentral
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {/* prettier-ignore */}
        <List sx={{ listStyleType: "decimal" }} className={classes.listHeader}>
          Hälsa Hemma kan erbjuda patienter som tillhör Din Psykolog blodprover som de tar i patientens hem. 
          Syftet är att säkerställa att det inte är en kroppslig sjukdom som får
          patienten att må dåligt. De kontrollerar då blodvärde, vita blodkroppar,
          blodplättar, sköldkörtelprover och prov för att kontrollera om patienten har diabetes.
          Pris: 0 - 250kr. Frikort gäller.
          <ListItem className={classes.listItem}>
            Säkerställ att patienten vet att hen behöver vara listad på Hälsa
            Hemmas vårdcentral för att de ska kunna ta proverna. Hälsa Hemma
            genomför sedan undersökning och provtagning i patientens hem när det
            finns behov för det. Här kan patienten ladda ner Hälsa Hemma-appen:{" "}
            <Link
              href={halsaHemmaAppUrl}
              target="_blank"
              underline="always"
              rel="noreferrer"
              color="black"
            >
              https://halsahemma.se/app.
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
          Informera patienten om att du nu startar ett ärende i Hälsa Hemma-appen. De kommer kontakta 
          patienten via telefon. Patienten laddar själv ner Hälsa Hemma-appen och kan även fortsätta 
          dialogen med Hälsa Hemmas personal där.
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={(e) => {
            e.target.disabled = true
            onRegisterPatientForHalsaHemma(e)
          }}
          variant="contained"
        >
          Hänvisa patienten
        </Button>
      </DialogActions>
    </Popover>
  )
}

export default HalsaHemmaDialog
