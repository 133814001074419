import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ButtonGroup,
  Button,
  CircularProgress,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
  ExpandMore as ExpandMoreIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material/"
import config from "config"
import {
  fetchPatientsAndSessions,
  fetchSessions,
  fetchActiveChats,
  fetchInactiveChats,
} from "redux/actions"
import { SessionTableWrapper } from "./components/SessionsTableWrapper"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: "bold",
  },
  accordionTitle: {
    color: theme.palette.white,
    textDecoration: "none",
    fontWeight: "600",
  },
  accordion: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    border: "0px",
  },
  accordionDetails: {
    backgroundColor: theme.palette.lighter_gray,
    color: theme.palette.white,
  },
  icon: {
    color: theme.palette.white,
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  errorButton: {
    marginTop: theme.spacing(2),
  },
  errorIcon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
  },
}))

const Sessions = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    patients,
    sessions: { past: pastSessions, loading, error },
    user,
    chats: { activeInstances, inactiveInstances },
  } = useSelector((store) => store)
  const { userId: caregiverId } = user

  useEffect(() => {
    if (patients?.length === 0) {
      dispatch(fetchPatientsAndSessions(caregiverId))
    } else {
      dispatch(fetchSessions(caregiverId))
    }
    if (!activeInstances?.length) {
      dispatch(fetchActiveChats())
    }
    if (!inactiveInstances?.length) {
      dispatch(fetchInactiveChats())
    }
  }, [])

  const handlePastSessions = () => {
    const sessionCounters = new Map()
    pastSessions.sort((a, b) => new Date(a.startedAt) - new Date(b.startedAt))
    pastSessions.forEach((session) => {
      const { userId, costReceiver } = session
      const sessionCounter = sessionCounters.get(userId) || 0
      if (costReceiver !== "ignore") {
        sessionCounters.set(userId, sessionCounter + 1)
        session.sessionNumber = sessionCounter + 1
      } else {
        session.sessionNumber = "ignorerad"
      }
    })

    return pastSessions
  }

  if (loading) {
    return (
      <Box className={classes.loading}>
        <CircularProgress size={50} color="primary" />
      </Box>
    )
  }

  if (error) {
    return (
      <Box className={classes.loading}>
        <ErrorOutlineIcon color="primary" className={classes.errorIcon} />
        <Typography variant="h4">Något gick fel!</Typography>
        <Button
          variant="contained"
          onClick={() => dispatch(fetchPatientsAndSessions(caregiverId))}
          className={classes.errorButton}
        >
          Försök igen
        </Button>
      </Box>
    )
  }
  return (
    <div className={classes.root}>
      <Box m={2}>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <ButtonGroup variant="outlined" color="primary">
            <Button href={config.kaddioUrl} target="_blank">
              Kaddio
            </Button>
            <Button href={config.guideUrl} target="_blank">
              Psykologguide
            </Button>
          </ButtonGroup>
        </Box>
        <Box ml={2} mt={2}>
          <Typography color="primary" variant="h6" className={classes.title}>
            Mina slutförda sessioner
          </Typography>
        </Box>
        <Accordion
          variant="outlined"
          className={classes.accordion}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
          >
            <Typography variant="body1" className={classes.accordionTitle}>
              Osignerade sessioner (skapas automatiskt i Kaddio)
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <SessionTableWrapper
              data={handlePastSessions()}
              patients={patients}
              emptyText="Det finns inga journalanteckningar."
            />
          </AccordionDetails>
        </Accordion>
        <Accordion variant="outlined" className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
          >
            <Typography variant="body1" className={classes.accordionTitle}>
              Signerade sessioner
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <SessionTableWrapper
              journalized
              data={handlePastSessions()}
              patients={patients}
              emptyText="Det finns inga besök som ska journalföras."
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  )
}

export default Sessions
