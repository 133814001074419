import format from "date-fns/format"
import parse from "date-fns/parse"
import addMinutes from "date-fns/addMinutes"

export const getTimeSlotsBetweenTimesWithDefinedInterval = (
  start,
  end,
  interval
) => {
  const timeFormat = "HH:mm"
  const timeSlots = []
  let slotTime = parse(start, timeFormat, new Date())
  const endTime = parse(end, timeFormat, new Date())
  while (slotTime <= endTime) {
    timeSlots.push(format(slotTime, timeFormat))
    slotTime = addMinutes(slotTime, interval)
  }
  return timeSlots
}
