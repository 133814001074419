import React, { useCallback, useEffect } from "react"
import { Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { connect, useDispatch } from "react-redux"
import config from "config"
import { ikbtStatus } from "utils/ikbtStatus"
import {
  fetchIKBTPatients,
  motivateIKBTPatient,
  reactivateIKBTPatient,
  updateIKBTFeedback,
  updateIKBTPatientStatus,
} from "redux/actions"
import { PatientStatusTable } from "./components/PatientStatusTable"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    textTransform: "uppercase",
  },
}))

const IkbtOverview = ({ ikbtPatients }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const refetchIKBTPatients = useCallback(() => {
    dispatch(fetchIKBTPatients())
  }, [dispatch])

  const updateIKBTStatus = useCallback(
    (patient, status) => {
      dispatch(updateIKBTPatientStatus(patient, status))
    },
    [dispatch]
  )

  const reactivate = useCallback(
    (patient) => {
      dispatch(reactivateIKBTPatient(patient))
    },
    [dispatch]
  )

  const motivate = useCallback(
    (patient) => {
      dispatch(motivateIKBTPatient(patient))
    },
    [dispatch]
  )

  const giveFeedback = useCallback(
    (patient) => {
      dispatch(updateIKBTFeedback(patient))
    },
    [dispatch]
  )

  useEffect(() => {
    refetchIKBTPatients()
  }, [refetchIKBTPatients])

  return (
    <div className={classes.root}>
      <Box m={2}>
        <Box ml={2} mt={2} mb={2}>
          <Typography color="primary" variant="h6" className={classes.title}>
            IKBT patientöversikt
          </Typography>
        </Box>
        <PatientStatusTable
          patients={ikbtPatients}
          onFeedbackChange={(patient, value) => {
            if (value) {
              giveFeedback(patient.patientId)
            }
          }}
          onPause={(patient) => {
            updateIKBTStatus(patient.patientId, ikbtStatus.Paused)
          }}
          onComplete={(patient) => {
            updateIKBTStatus(patient.patientId, ikbtStatus.Completed)
          }}
          onSignJournal={(patient) => {
            window.open(
              `${config.kaddioUrl}/client-dashboard/${patient.patientKaddioId}`,
              "_blank"
            )
          }}
          onReactivate={(patient, value) => {
            if (value) {
              reactivate(patient.patientId)
            }
          }}
          onMotivate={(patient, value) => {
            if (value) {
              motivate(patient.patientId)
            }
          }}
        />
      </Box>
    </div>
  )
}

export default connect((state) => ({
  ikbtPatients: state.ikbtPatients,
}))(IkbtOverview)
