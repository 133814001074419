import React, { useContext } from "react"
import { makeStyles } from "@mui/styles"

import { anonymousModeContext } from "contexts"

const useStyles = makeStyles(() => {
  const styles = {
    color: "transparent",
    textShadow: "0 0 10px rgb(0 0 0 / 50%)",
  }

  return {
    wrapper: () => ({
      ...styles,
      "& div": { ...styles },
      "& img": { display: "none" },
      "& video": { display: "none" },
    }),
  }
})

const AnonymousModeWrapper = ({ children }) => {
  const classes = useStyles()
  const [anonymousMode] = useContext(anonymousModeContext)

  if (anonymousMode) {
    return <span className={classes.wrapper}>{children}</span>
  }

  return children
}

export default AnonymousModeWrapper
