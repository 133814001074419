/* eslint-disable max-len */
import { Box, Typography, Link } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useEffect, useState } from "react"

const useStyles = makeStyles((theme) => ({
  root: ({ availableSlots }) => ({
    backgroundColor: availableSlots
      ? theme.palette.warning.main
      : theme.palette.error.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
    color: "white",
    [theme.breakpoints.down("sm")]: {
      alignItems: "start",
    },
  }),
  bannerContent: {
    display: "inherit",
    alignItems: "flex-start",
  },
  bannerText: {
    fontSize: "1rem",
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem",
      textAlign: "left",
    },
  },
  link: {
    color: theme.palette.white,
    textDecoration: "underline",
    fontStyle: "italic",
    fontWeight: 600,
  },
}))

const Banner = ({ availableSlots, caregiverFirstName }) => {
  const classes = useStyles({ availableSlots })
  const [bannerText, setBannerText] = useState("")

  useEffect(() => {
    const commonText = "för dina patienter. Vänligen lägg upp fler tider"
    if (availableSlots === 0) {
      setBannerText(
        <>
          Hej {caregiverFirstName}! Du har{" "}
          <Link
            href={`${window.location.origin}?calendar-view=week`}
            className={classes.link}
          >
            inga lediga tider
          </Link>{" "}
          {`${commonText}.`}
        </>
      )
    } else {
      setBannerText(
        <>
          Hej {caregiverFirstName}! Du har endast{" "}
          <Link
            href={`${window.location.origin}?calendar-view=week`}
            className={classes.link}
          >
            {`${availableSlots} ${
              availableSlots === 1 ? "ledig tid" : "lediga tider"
            }`}
          </Link>{" "}
          {`${commonText} när du har möjlighet.`}
        </>
      )
    }
  }, [availableSlots])

  return (
    <Box className={classes.root}>
      <Typography className={classes.bannerContent}>
        <Typography variant="subtitle2" className={classes.bannerText}>
          {bannerText}
        </Typography>
      </Typography>
    </Box>
  )
}

export default Banner
