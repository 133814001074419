/* eslint-disable no-nested-ternary */
import React, { useState } from "react"
import ChatPreviewDialog from "./ChatPreviewDialog"
import { SessionTable } from "./SessionsTable"

export const SessionTableWrapper = ({
  journalized = false,
  data = [],
  patients = [],
  emptyText = "",
}) => {
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("startedAt")
  const [openChatPreviewDialog, setOpenChatPreviewDialog] = useState(false)
  const [chatPreviewSession, setChatPreviewSession] = useState(null)
  const getPatient = (userId) => patients.find((el) => el.userId === userId)

  const getPatientInfo = (userId, includeAllData) => {
    const patient = getPatient(userId)

    if (patient) {
      return includeAllData
        ? patient
        : `${patient.firstName} ${patient.lastName}, ${patient.socialSecurity}`
    }
    return userId
  }

  const compareElements = (a, b) => {
    let x = ""
    let y = ""
    switch (orderBy) {
      case "startedAt":
        x = a.startedAt
        y = b.startedAt
        break
      case "patient":
      default:
        x = getPatientInfo(a?.userId, false).toLowerCase()
        y = getPatientInfo(b?.userId, false).toLowerCase()
        break
    }
    if (x === y) {
      x = a.sessionNumber
      y = b.sessionNumber
    }
    if (order === "desc") {
      return x < y ? 1 : -1
    }
    return y < x ? 1 : -1
  }

  const handleOpenChatPreview = (session) => {
    setChatPreviewSession(session)
    setOpenChatPreviewDialog(true)
  }

  const handleCloseChatPreview = () => {
    setOpenChatPreviewDialog(false)
    setChatPreviewSession(null)
  }

  const getData = () =>
    data.length
      ? data
          .sort(compareElements)
          .filter((session) =>
            journalized ? session.journalized : !session.journalized
          )
          .map((session) => ({
            ...session,
            patient: getPatientInfo(session.userId, false),
          }))
      : []

  return (
    <>
      <SessionTable
        data={getData()}
        order={order}
        orderBy={orderBy}
        emptyText={emptyText}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        handleOpenChatPreview={handleOpenChatPreview}
      />
      {chatPreviewSession && (
        <ChatPreviewDialog
          onClose={handleCloseChatPreview}
          open={openChatPreviewDialog}
          session={chatPreviewSession}
        />
      )}
    </>
  )
}
