import React, { useState } from "react"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    alignItems: "center",
    marginTop: "5px",
    marginLeft: "6px",
    fontSize: "16px",
    fontWeight: "500",
    "&:hover span:last-child": {
      opacity: "1",
    },
  },
  tooltip: {
    transition: "all 0.2s ease-out",
    display: "inline-block",
    marginLeft: "5px",
    opacity: "0",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  userid: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}))

const PatientCopyId = ({ id }) => {
  const idToCopy = id.slice(0, 5)
  const classes = useStyles()

  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = (e) => {
    e.stopPropagation()
    navigator.clipboard.writeText(idToCopy)

    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <div aria-hidden="true" onClick={copyToClipboard} className={classes.root}>
      <span className={classes.userid}>(ID: {idToCopy})</span>
      <span className={classes.tooltip}>
        {isCopied ? "kopierad" : "kopiera"}
      </span>
    </div>
  )
}

export default PatientCopyId
