import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useCallback,
} from "react"
import { useLocation } from "react-router-dom"
import { isSameDay } from "date-fns"
import { connect } from "react-redux"
import classNames from "classnames"
import { Box, Typography, CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"
import TitleDivider from "pages/chat/components/TitleDivider"
import ChatBubble from "pages/chat/components/ChatBubble"
import useScript from "react-script-hook"
import { fetchEntries, setChatMessagesPagination } from "redux/actions"
import { entryType } from "utils/entryType"
import { defaultPagination } from "utils"

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    "&.inactive": {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  bottomOfChat: {
    height: "16px",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
}))

const ChatContainer = ({
  messages,
  messagesCount,
  messagesLoading,
  messagesPagination: pagination,
  user,
  active,
  sessions,
  activeChat,
  dispatch,
}) => {
  const { userId: caregiverId } = user
  const classes = useStyles()
  const messagesEndRef = useRef(null)
  const sessionRef = useRef(null)
  const { state } = useLocation()
  const [sessionScroll, setSessionScroll] = useState(!!state)
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const [lastMessageId, setLastMessageId] = useState(null)

  useEffect(() => {
    // NOTE: Delay for scroll to bottom. otherwise rendered
    // without calculating with patient info
    const lastMessage = messages[messages.length - 1]
    if (lastMessage?.id !== lastMessageId) {
      setLastMessageId(lastMessage?.id)
    }
    const timeoutID = setTimeout(() => {
      if (sessionScroll && sessionRef.current) {
        sessionRef.current.scrollIntoView()
        // this prevents trying to scroll to the session header if the user refreshes the page / opens a different user
        window.history.replaceState(null, document.title)
        setSessionScroll(false)
      }
    }, 100)

    return () => {
      clearTimeout(timeoutID)
    }
  }, [messages])

  useEffect(() => {
    if (lastMessageId) {
      messagesEndRef.current.scrollIntoView({
        block: "end",
      })
      setScrolledToBottom(true)
    }
  }, [lastMessageId])

  useEffect(
    () => () => {
      dispatch(setChatMessagesPagination(defaultPagination))
      setScrolledToBottom(false)
    },
    [activeChat?.chatId]
  )

  useEffect(() => {
    if (pagination.skip) {
      dispatch(fetchEntries(activeChat?.chatId, pagination))
    }
  }, [pagination])

  const observer = useRef()
  const firstUserMessageRef = useCallback(
    (node) => {
      if (messagesLoading) return
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        const hasMore = messagesCount > messages.length
        if (entries[0]?.isIntersecting && scrolledToBottom && hasMore) {
          dispatch(
            setChatMessagesPagination({
              skip: pagination.skip + pagination.limit,
              limit: pagination.limit,
            })
          )
        }
      })
      if (node) observer.current.observe(node)
    },
    [scrolledToBottom, messagesCount, messages]
  )

  const statuspageId =
    process.env.NODE_ENV === "production" ? "dinpsykolog" : "dptest"
  useScript({
    src: `https://${statuspageId}.statuspage.io/embed/script.js`,
    checkForExisting: true,
  })

  const getSessionNumber = (sessionId) => {
    const activeSession = sessions?.active?.find(
      (element) => element.id === sessionId
    )
    if (activeSession) {
      return activeSession.sessionNumber
    }
    return (
      sessions?.past?.find((element) => element.id === sessionId)
        ?.sessionNumber || null
    )
  }

  const checkLastSeenMessage = (msgs, msgId) => {
    const lastSeenMessage = msgs
      .filter(
        (msg) =>
          [entryType.DEFAULT, entryType.SYSTEM].includes(msg.type) &&
          msg.seen &&
          caregiverId === msg.fromUserId
      )
      .pop()

    return msgId === lastSeenMessage?.id
  }

  const checkFirstUserMessage = (msgs, msgId) => {
    const index = msgs.findIndex((msg) => msg.id === msgId)

    return index === 0
  }

  return (
    <Box
      className={classNames(classes.root, {
        inactive: !active,
      })}
    >
      {!active && (
        <Typography variant="h5">Välj klient till vänster</Typography>
      )}
      <Box className={classes.wrapper}>
        {active && messagesLoading && (
          <Box className={classes.loading}>
            <CircularProgress size={50} color="primary" />
          </Box>
        )}
        {active &&
          messages.map((message, index) => {
            if (
              index === 0 &&
              messages[index + 1] &&
              messagesCount === messages.length
            ) {
              message.sessionId = messages[index + 1].sessionId
            }
            const { id, createdAt, sessionId, fromUserId } = message
            const isLastCaregiverSeenMessage = checkLastSeenMessage(
              messages,
              id
            )
            const isFirstUserMessage = checkFirstUserMessage(messages, id)
            const sessionHeader =
              (index === 0 && messagesCount === messages.length) ||
              (index > 0 &&
                messages[index - 1].sessionId !== sessionId &&
                fromUserId !== caregiverId) ? (
                <TitleDivider
                  sessionNum={getSessionNumber(sessionId)}
                  ref={
                    state && state === getSessionNumber(sessionId)
                      ? sessionRef
                      : null
                  }
                />
              ) : null
            if (
              index > 0 &&
              isSameDay(
                new Date(messages[index - 1]?.createdAt),
                new Date(createdAt)
              )
            ) {
              return (
                <Fragment key={`${id}_${createdAt}`}>
                  {sessionHeader}
                  <ChatBubble
                    key={id}
                    activeChat={activeChat}
                    caregiverId={caregiverId}
                    message={message}
                    isLastMessage={isLastCaregiverSeenMessage}
                    isFirstUserMessage={isFirstUserMessage}
                    firstUserMessageRef={firstUserMessageRef}
                  />
                </Fragment>
              )
            }
            return (
              <Fragment key={`${id}_${createdAt}`}>
                {sessionHeader}
                <TitleDivider timeStamp={createdAt} />
                <ChatBubble
                  key={id}
                  activeChat={activeChat}
                  caregiverId={caregiverId}
                  message={message}
                  isLastMessage={isLastCaregiverSeenMessage}
                  isFirstUserMessage={isFirstUserMessage}
                  firstUserMessageRef={firstUserMessageRef}
                />
              </Fragment>
            )
          })}
        <div className={classes.bottomOfChat} ref={messagesEndRef} />
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ user, chats, sessions }) => {
  const {
    activeChat,
    messagesCount,
    messages,
    messagesPagination,
    messagesLoading,
  } = chats
  return {
    user,
    sessions,
    activeChat,
    messagesCount,
    messages,
    messagesPagination,
    messagesLoading,
  }
}

export default connect(mapStateToProps)(ChatContainer)
