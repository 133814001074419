import Api from "api"

/**
 * Reactivates Ikbt Patient.
 *
 * @param {string} patientId
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const reactivateIkbtPatient = (patientId) =>
  Api.put("/users/caregiver/ikbt/reactivatePatient", {
    patientId,
  })
