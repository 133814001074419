import { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: "0.8",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: "1",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      pointerEvents: "none",
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1.25),
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}))

const CancelMeetingConfirmationDialog = ({
  onClose,
  openDialog,
  onMeetingCancellation,
  error,
}) => {
  const classes = useStyles()
  const [deleteAvailabilityCheck, setDeleteAvailabilityCheck] = useState(true)

  return (
    <Dialog open={openDialog} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Avboka videosamtal</DialogTitle>
      <DialogContent>
        Är du säker på att du vill avboka videosamtalet?
        <FormControlLabel
          control={
            <Checkbox
              checked={deleteAvailabilityCheck}
              onChange={() =>
                setDeleteAvailabilityCheck(!deleteAvailabilityCheck)
              }
            />
          }
          label="Ta även bort tillgängligheten"
          labelPlacement="end"
        />
        {error && (
          <Typography
            className={classes.errorMessage}
            variant="caption"
            component="p"
          >
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Nej
        </Button>
        <Button
          onClick={(e) => {
            e.target.disabled = true
            onMeetingCancellation(deleteAvailabilityCheck)
          }}
          className={classes.confirmButton}
          variant="contained"
        >
          Avboka
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelMeetingConfirmationDialog
