import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker"
import { TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import sv from "date-fns/locale/sv"
import { makeStyles } from "@mui/styles"
import { useController } from "react-hook-form"

const useStyles = makeStyles(() => ({
  labelText: {
    "& label": {
      color: "#A8A8A8",
      fontWeight: "300",
    },
  },
}))

const DatePicker = ({
  name,
  label,
  controller,
  minDate,
  maxDate,
  disabled,
}) => {
  const classes = useStyles()
  const { field, fieldState } = useController({
    name,
    control: controller,
  })
  const { onChange, ...rest } = field || {}
  const error = fieldState?.error
  const helperText = error?.message
  const invalid = !!error?.message

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <MuiDatePicker
        {...rest}
        inputVariant="outlined"
        mask="____-__-__"
        inputFormat="yyyy-MM-dd"
        inputProps={{
          placeholder: "ÅÅÅÅ-MM-DD",
        }}
        cancelText="Avbryt"
        label={label}
        onChange={(newValue) => onChange(newValue)}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            className={classes.labelText}
            helperText={helperText}
            label={label}
            {...params}
            error={invalid}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
