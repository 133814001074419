import Api from "api"

/**
 * Updates Ikbt Patient Status.
 *
 * @param {string} patientId
 * @param {number} status
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const updateIkbtPatientStatus = (patientId, status) =>
  Api.put("/users/caregiver/ikbt/updatePatientStatus", {
    patientId,
    status,
  })
