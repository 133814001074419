import React, { useState, useEffect, useCallback } from "react"
import isAfter from "date-fns/isAfter"
import { CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Cookie from "js-cookie"
import { setCookie } from "utils/setCookie"

const useStyles = makeStyles(() => ({
  root: (height) => ({
    height,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiCircularProgress-root": {
      color: "#357376",
    },
  }),
}))

const checkIfOldBuildDate = (
  latestBuildDateInMilliseconds,
  latestCacheDeletionDateInMilliseconds
) => {
  const latestBuildDate = new Date(latestBuildDateInMilliseconds)
  const latestCacheDeletionDate = latestCacheDeletionDateInMilliseconds
    ? new Date(latestCacheDeletionDateInMilliseconds)
    : null

  return (
    !latestCacheDeletionDate ||
    isAfter(latestBuildDate, latestCacheDeletionDate)
  )
}

const deleteCacheAndReload = async (
  latestBuildDateInMilliseconds,
  setIsLatestBuildDate
) => {
  try {
    // Delete cache and reload the page
    const { caches } = window
    const cacheNames = await caches.keys()
    for (const cacheName of cacheNames) {
      await caches.delete(cacheName)
    }
    const year = 365
    setCookie(
      "latestCacheDeletionDateCaregiver",
      latestBuildDateInMilliseconds,
      year
    )
    console.log("Cache deleted")
    window.location.reload()
  } catch (error) {
    console.error("Error while deleting cache: ", error)
    setIsLatestBuildDate(true)
  }
}

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)
    const [height, setHeight] = useState(window.innerHeight - 0.1)
    const classes = useStyles(height)
    const handleHeight = useCallback((e) => {
      setHeight(e.target.innerHeight)
    }, [])

    useEffect(() => {
      window.addEventListener("resize", handleHeight)
      return () => {
        window.removeEventListener("resize", handleHeight)
      }
    }, [handleHeight])

    useEffect(() => {
      fetch("/meta.json", {
        headers: {
          "Cache-Control": "no-store",
          Pragma: "no-cache",
          Expires: 0,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((meta) => {
          const latestBuildDateInMilliseconds = meta.buildDate
          const latestCacheDeletionDateFromCookie = Cookie.get(
            "latestCacheDeletionDateCaregiver"
          )
          const latestCacheDeletionDateInMilliseconds =
            latestCacheDeletionDateFromCookie
              ? parseInt(latestCacheDeletionDateFromCookie)
              : null
          const shouldForceRefresh =
            window?.caches &&
            checkIfOldBuildDate(
              latestBuildDateInMilliseconds,
              latestCacheDeletionDateInMilliseconds
            )
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false)
            console.log("Should delete cache")
            deleteCacheAndReload(
              latestBuildDateInMilliseconds,
              setIsLatestBuildDate
            )
          } else {
            setIsLatestBuildDate(true)
            console.log("Should not delete cache")
          }
        })
    }, [])

    return isLatestBuildDate ? (
      <Component {...props} />
    ) : (
      <div className={classes.root}>
        <CircularProgress size={50} />
      </div>
    )
  }

  return ClearCacheComponent
}

export default withClearCache
