import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  List,
  ListItem,
  IconButton,
  DialogActions,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Close as CloseIcon } from "@mui/icons-material/"
import braiveLogoWithText from "assets/braive_logo_with_text.svg"

const useStyles = makeStyles((theme) => ({
  title: {
    "& img": {
      marginRight: theme.spacing(2),
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  actions: {
    justifyContent: "center",
    margin: theme.spacing(1, 0, 2),
    "& .MuiButton-root": {
      borderRadius: "50px",
      textTransform: "none",
      padding: theme.spacing(2, 5),
      "&:disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        boxShadow: "none",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        pointerEvents: "none",
      },
    },
  },
  content: {
    overflowY: "auto",
    overflowX: "auto",
    margin: theme.spacing(0, 2),
    paddingRight: 0,
    "& .MuiList-root": {
      paddingTop: 0,
    },
  },
  listItem: {
    display: "list-item",
    fontSize: "0.95rem",
    fontWeight: 500,
    lineHeight: 1.57,
    padding: theme.spacing(1),
  },
}))

const IkbtDialog = ({ open, onClose, handleInvitePatientToIkbt }) => {
  const classes = useStyles()

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>
        <IconButton
          onClick={() => {
            onClose()
          }}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
        <img alt="Braive logo" src={braiveLogoWithText} width="150px" />
        <Typography variant="subtitle2">Bjud in patient till IKBT</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <List sx={{ listStyleType: "decimal" }}>
          <ListItem className={classes.listItem}>
            Informera patienten om IKBT och Braive
          </ListItem>
          <ListItem className={classes.listItem}>
            Bjud in patienten till Braive nedan
          </ListItem>
          <ListItem className={classes.listItem}>
            Be patienten fylla i MHC till nästa samtal
          </ListItem>
          <ListItem className={classes.listItem}>
            Boka nästa videosamtal
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={(e) => {
            handleInvitePatientToIkbt(e)
          }}
          variant="contained"
        >
          Bjud in patient till Braive
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default IkbtDialog
