import { Table } from "components/Table"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Typography,
  useEventCallback,
} from "@mui/material"
import { ExpandMore as ExpandMoreIcon, Close } from "@mui/icons-material"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { makeStyles } from "@mui/styles"
import { differenceInWeeks } from "date-fns"
import { ikbtStatus } from "utils/ikbtStatus"
import { PatientStatus } from "./PatientStatus"

const useStyles = makeStyles((theme) => ({
  accordionTitle: {
    color: theme.palette.white,
    textDecoration: "none",
    fontWeight: "400",
  },
  accordion: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderLeft: "0px",
    borderRight: "0px",
  },
  tableLabelCentered: {
    display: "block",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
  accordionDetails: {
    backgroundColor: theme.palette.lighter_gray,
    color: theme.palette.white,
  },
  icon: {
    color: theme.palette.white,
  },
  dialogContent: {
    margin: theme.spacing(0, 8),
  },
  actionButton: {
    textTransform: "none",
    padding: theme.spacing(1, 4),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderRadius: theme.spacing(4),
    fontSize: "1.2rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 1),
      borderRadius: theme.spacing(2),
      fontSize: "1rem",
    },
  },
  actionButtonSecondary: {
    textTransform: "none",
    padding: theme.spacing(1, 4),
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    borderRadius: theme.spacing(4),
    borderColor: theme.palette.primary.main,
    border: "1px solid",
    fontSize: "1.2rem",
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
  tableCell: {
    padding: `${theme.spacing(1, 0)} !important`,
  },
}))

const requiresFeedback = (patient) =>
  patient.patientStatus === ikbtStatus.Active && !patient.sessionFeedback

const requiresJournal = (patient) =>
  patient.patientStatus === ikbtStatus.Active &&
  patient.nonJournalizedSessionIds?.length > 0 &&
  patient.sessionFeedback

const requiresMotivation = (patient) =>
  patient.patientStatus === ikbtStatus.Inactive && !patient.motivate

const canPause = (patient) =>
  patient.patientStatus === ikbtStatus.Inactive &&
  differenceInWeeks(new Date(), new Date(patient.lastLogin)) >= 2

const requiresAction = (patient) =>
  requiresFeedback(patient) ||
  requiresJournal(patient) ||
  requiresMotivation(patient) ||
  canPause(patient)

const PatientTableTitle = ({ title, count = 0 }) => {
  const classes = useStyles()

  return (
    <Stack direction="row" spacing={2}>
      <Typography
        variant="body1"
        className={classes.accordionTitle}
        textTransform="uppercase"
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={classes.accordionTitle}
        textTransform="uppercase"
      >
        {count} ST
      </Typography>
    </Stack>
  )
}

const formatPatientName = ({ rowData }) => (
  <>
    <Typography variant="body2">
      {rowData.patientName} | ID {rowData.patientId?.slice(0, 5)}
    </Typography>
    <Typography variant="body2">Sessioner {rowData.sessionNumber}</Typography>
  </>
)

const formatStatus = ({ rowData }) => <PatientStatus patient={rowData} />

/**
 * Renders a table with IKBT patients and their status.
 *
 * @template T
 * @param {Array<T>} patients
 * @param {(patient: T, value: boolean) => void} onFeedbackChange
 * @param {(patient: T, value: boolean) => void} onReactivate
 * @param {(patient: T, value: boolean) => void} onMotivate
 * @param {(patient: T) => void} onSignJournal
 * @param {(patient: T) => void} onPause
 * @param {(patient: T) => void} onComplete
 *
 * @return {JSX.Element}
 */
export const PatientStatusTable = ({
  patients = [],
  onFeedbackChange,
  onSignJournal,
  onPause,
  onReactivate,
  onMotivate,
  onComplete,
}) => {
  const classes = useStyles()
  const defaultPanel = useRef()
  const [openedPanel, setOpenedPanel] = useState()

  const [isPauseCompleteDialogOpen, setIsPauseCompleteDialogOpen] =
    useState(false)
  const [isPauseDialogOpen, setIsPauseDialogOpen] = useState(false)
  const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false)
  const [pauseCompletePatient, setPauseCompletePatient] = useState()

  const onFeedbackChangeEvent = useEventCallback(onFeedbackChange)
  const onSignJournalEvent = useEventCallback(onSignJournal)
  const onPauseEvent = useEventCallback(onPause)
  const onReactivateEvent = useEventCallback(onReactivate)
  const onMotivateEvent = useEventCallback(onMotivate)
  const onCompleteEvent = useEventCallback(onComplete)

  const columns = useMemo(
    () => [
      {
        id: "patientName",
        sortable: false,
        label: "Patient",
        mobileTitle: true,
        formatBodyCell: formatPatientName,
      },
      {
        id: "status",
        sortable: false,
        label: <span className={classes.tableLabelCentered}>Status</span>,
        formatBodyCell: formatStatus,
      },
      {
        id: "action",
        sortable: false,
        label: <span className={classes.tableLabelCentered}>Åtgärd</span>,
        // eslint-disable-next-line react/no-unstable-nested-components
        formatBodyCell: ({ rowData }) => {
          const feedbackAction = requiresFeedback(rowData)

          if (feedbackAction) {
            return (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <FormControlLabel
                  labelPlacement="start"
                  control={<Switch />}
                  label="Återkopplat i Braive?"
                  onChange={(_, checked) =>
                    onFeedbackChangeEvent(rowData, checked)
                  }
                />
              </Box>
            )
          }

          const journalAction = requiresJournal(rowData)

          if (journalAction) {
            // @todo There should be a dialog allowing to end the treatment in case a patient completed all modules
            //    instead of sign journal event.
            return (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className={classes.actionButton}
                  onClick={() => onSignJournalEvent(rowData)}
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  Signera Journal
                </Button>
              </Box>
            )
          }

          const motivationAction = requiresMotivation(rowData)

          if (motivationAction) {
            return (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <FormControlLabel
                  labelPlacement="start"
                  control={<Switch />}
                  label="Uppumuntrat till aktivitet?"
                  onChange={(_, checked) => onMotivateEvent(rowData, checked)}
                />
              </Box>
            )
          }

          const pauseAction = canPause(rowData)

          if (pauseAction) {
            return (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className={classes.actionButton}
                  onClick={() => {
                    setPauseCompletePatient(rowData)
                    setIsPauseCompleteDialogOpen(true)
                  }}
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  Avsluta / Pausa
                </Button>
              </Box>
            )
          }

          if (rowData.patientStatus === ikbtStatus.Paused) {
            return (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <FormControlLabel
                  labelPlacement="start"
                  control={<Switch />}
                  label="Återakivera?"
                  onChange={(_, checked) => onReactivateEvent(rowData, checked)}
                />
              </Box>
            )
          }

          return <Typography textAlign="center">Ingen åtgärd krävs</Typography>
        },
      },
      {
        id: "pause",
        sortable: false,
        label: (
          <span className={classes.tableLabelCentered}>Avsluta / Pausa</span>
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        formatBodyCell: ({ rowData }) => {
          if (rowData.status === ikbtStatus.Completed) {
            return null
          }

          return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                onClick={() => {
                  setPauseCompletePatient(rowData)

                  // Skip the dialog and go straight to the completion dialog
                  // for paused patients.
                  if (rowData.patientStatus === ikbtStatus.Paused) {
                    setIsCompleteDialogOpen(true)
                  } else {
                    setIsPauseCompleteDialogOpen(true)
                  }
                }}
              >
                <Close />
              </IconButton>
            </Box>
          )
        },
      },
    ],
    [
      classes.tableLabelCentered,
      classes.actionButton,
      onFeedbackChangeEvent,
      onSignJournalEvent,
      onMotivateEvent,
      onReactivateEvent,
    ]
  )

  const columnsCompleted = useMemo(
    () => columns.filter((column) => column.id !== "pause"),
    [columns]
  )

  const columnsNoAction = useMemo(
    () =>
      columns.map((column) => {
        if (column.id === "action") {
          return {
            ...column,
            // eslint-disable-next-line react/no-unstable-nested-components
            formatBodyCell: () => (
              <Typography textAlign="center">Ingen åtgärd krävs</Typography>
            ),
          }
        }

        return column
      }),
    [columns]
  )

  const columnsPaused = useMemo(
    () =>
      columns.map((column) => {
        if (column.id === "pause") {
          return {
            ...column,
            label: <span className={classes.tableLabelCentered}>Avsluta</span>,
          }
        }

        return column
      }),
    [classes.tableLabelCentered, columns]
  )

  const patientsSegregated = useMemo(
    () =>
      patients.reduce((acc, item) => {
        let key = item.patientStatus

        // Completed overrides the session number rule.
        if ([ikbtStatus.Active, ikbtStatus.Inactive].includes(key)) {
          // Render to be either action_required or no_action_required.
          key =
            (item.sessionNumber !== 0 || key === ikbtStatus.Inactive) &&
            requiresAction(item)
              ? "action_required"
              : "no_action"
        }

        if (!Array.isArray(acc[key])) {
          acc[key] = []
        }

        acc[key].push(item)

        return acc
      }, {}),
    [patients]
  )

  const openPanelHandler = useCallback(
    (panel) => () => {
      if (patientsSegregated[panel]?.length > 0) {
        setOpenedPanel(panel)
      }
    },
    [patientsSegregated]
  )

  useEffect(() => {
    // On patients change make sure to not keep opened panel if it's empty.
    if (!patientsSegregated[openedPanel]?.length) {
      defaultPanel.current = null
      setOpenedPanel()
    }
  }, [patientsSegregated, openedPanel])

  const isPanelOpen = (panel) => {
    if (
      !defaultPanel.current &&
      !openedPanel &&
      patientsSegregated[panel]?.length > 0
    ) {
      defaultPanel.current = panel
      setOpenedPanel(panel)

      return false
    }

    return openedPanel === panel
  }

  return (
    <>
      <Dialog
        open={isPauseCompleteDialogOpen}
        onClose={() => setIsPauseCompleteDialogOpen(false)}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="close"
            onClick={() => setIsPauseCompleteDialogOpen(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Button
              className={classes.actionButton}
              onClick={() => {
                setIsPauseCompleteDialogOpen(false)
                setIsCompleteDialogOpen(true)
              }}
            >
              Avsluta behandling
            </Button>

            <Button
              className={classes.actionButton}
              onClick={() => {
                setIsPauseCompleteDialogOpen(false)
                setIsPauseDialogOpen(true)
              }}
            >
              Pausa behandling
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isCompleteDialogOpen}
        onClose={() => setIsCompleteDialogOpen(false)}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="close"
            onClick={() => setIsCompleteDialogOpen(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="body1" textAlign="center">
            Är du säker på att du vill avsluta patientens IKBT-behandling?
          </Typography>
          <Typography
            variant="body2"
            textAlign="center"
            color={(theme) => theme.palette.warning.darker}
          >
            OBS! Denna åtgärd går ej att ångra.
          </Typography>
        </DialogContent>
        <Box
          pb={2}
          px={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            gap: 2,
          }}
        >
          <Button
            fullWidth
            className={classes.actionButtonSecondary}
            onClick={() => setIsCompleteDialogOpen(false)}
          >
            Nej
          </Button>
          <Button
            fullWidth
            className={classes.actionButton}
            onClick={() => {
              setIsCompleteDialogOpen(false)
              onCompleteEvent(pauseCompletePatient)
            }}
          >
            Avsluta
          </Button>
        </Box>
      </Dialog>

      <Dialog
        open={isPauseDialogOpen}
        onClose={() => setIsPauseDialogOpen(false)}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="close"
            onClick={() => setIsPauseDialogOpen(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="body1" textAlign="center">
            Är du säker på att du vill pausa patientens IKBT-behandling?
          </Typography>
          <Typography variant="body2" textAlign="center">
            (Du kan återaktivera patienten senare)
          </Typography>
        </DialogContent>
        <Box
          pb={2}
          px={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            gap: 2,
          }}
        >
          <Button
            fullWidth
            className={classes.actionButtonSecondary}
            onClick={() => setIsPauseDialogOpen(false)}
          >
            Nej
          </Button>
          <Button
            fullWidth
            className={classes.actionButton}
            onClick={() => {
              setIsPauseDialogOpen(false)
              onPauseEvent(pauseCompletePatient)
            }}
          >
            Pausa
          </Button>
        </Box>
      </Dialog>

      <Accordion
        disableGutters
        elevation={2}
        square
        variant="outlined"
        className={classes.accordion}
        expanded={isPanelOpen("action_required")}
        onChange={openPanelHandler("action_required")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <PatientTableTitle
            title="Kräver åtgärd"
            count={patientsSegregated.action_required?.length}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table
            rowIdKey="patientId"
            data={patientsSegregated.action_required ?? []}
            columns={columns}
            tableCellClassnames={classes.tableCell}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        elevation={2}
        square
        variant="outlined"
        className={classes.accordion}
        expanded={isPanelOpen(ikbtStatus.Invited)}
        onChange={openPanelHandler(ikbtStatus.Invited)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <PatientTableTitle
            title="INBJUDNA"
            count={patientsSegregated[ikbtStatus.Invited]?.length}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table
            rowIdKey="patientId"
            data={patientsSegregated[ikbtStatus.Invited] ?? []}
            columns={columns}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        elevation={2}
        square
        variant="outlined"
        className={classes.accordion}
        expanded={isPanelOpen("no_action")}
        onChange={openPanelHandler("no_action")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <PatientTableTitle
            title="INGEN ÅTGÄRD KRÄVS"
            count={patientsSegregated.no_action?.length}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table
            rowIdKey="patientId"
            data={patientsSegregated.no_action ?? []}
            columns={columnsNoAction}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        elevation={2}
        square
        variant="outlined"
        className={classes.accordion}
        expanded={isPanelOpen(ikbtStatus.Paused)}
        onChange={openPanelHandler(ikbtStatus.Paused)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <PatientTableTitle
            title="PAUSADE"
            count={patientsSegregated[ikbtStatus.Paused]?.length}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table
            rowIdKey="patientId"
            data={patientsSegregated[ikbtStatus.Paused] ?? []}
            columns={columnsPaused}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        elevation={2}
        square
        variant="outlined"
        className={classes.accordion}
        expanded={isPanelOpen(ikbtStatus.Completed)}
        onChange={openPanelHandler(ikbtStatus.Completed)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <PatientTableTitle
            title="AVSLUTADE"
            count={patientsSegregated[ikbtStatus.Completed]?.length}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table
            rowIdKey="patientId"
            data={patientsSegregated[ikbtStatus.Completed] ?? []}
            columns={columnsCompleted}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
