import transform from "lodash.transform"
import isEqual from "lodash.isequal"
import isObject from "lodash.isobject"

// object Object compared
// base Object to compare with

export function difference(object, base) {
  function changes(obj, compareObj) {
    return transform(obj, (result, value, key) => {
      // const tmp = value === "" ? null : value
      if (!isEqual(value, compareObj[key])) {
        result[key] =
          isObject(value) && isObject(compareObj[key])
            ? changes(value, compareObj[key])
            : value
      }
    })
  }
  return changes(object, base)
}
