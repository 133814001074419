import Api from "api"

export const postSharedMaterialToKaddioContact = (
  caregiverKaddioId,
  patientKaddioContactId,
  body
) =>
  Api.post(
    `/users/caregiver/kaddio/${caregiverKaddioId}/addMaterialToContact/${patientKaddioContactId}/`,
    body
  )
