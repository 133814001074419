import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useEffect, useRef, useState } from "react"
import { useReactMediaRecorder } from "react-media-recorder"
import { postMessage } from "redux/actions"
import {
  Stop,
  Delete,
  Close,
  FiberManualRecord,
  Check,
} from "@mui/icons-material"
import { videoUpload } from "api/videoUpload"
import { connect } from "react-redux"
import isEmpty from "lodash.isempty"
import Timer from "components/Timer"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  videoContainer: {
    padding: theme.spacing(1),
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  videoRecorder: {
    height: "31.25rem",
    width: "100%",
    maxWidth: "27.5rem",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2, 1),
  },
  video: {
    alignSelf: "center",
  },
  replayButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  closeButton: {
    alignSelf: "flex-end",
    padding: theme.spacing(2, 1),
  },
  titleText: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 2, 1, 2),
  },
  loadingText: {
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  title: {
    justifyContent: "center",
  },
  closeButtonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  errorText: {
    color: theme.palette.error.main,
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  drawerButton: {
    padding: theme.spacing(1, 4),
    "& svg": {
      fontSize: "1.875rem",
    },
  },
  stopButtton: {
    "& svg": {
      fontSize: "3rem",
    },
  },
  startRecordingButton: {
    color: theme.palette.error.main,
    border: "2px solid #cccccc",
    borderRadius: "50%",
    padding: "0.063rem",
    "& svg": {
      fontSize: "5rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "4rem",
      },
      "& circle": {
        r: 11.5,
      },
    },
  },
}))

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream
    }
  }, [stream])
  if (!stream) {
    return null
  }
  return (
    <video ref={videoRef} width={350} height={250} autoPlay playsInline muted>
      <track kind="captions" />
    </video>
  )
}

const CameraDrawer = ({
  isCameraOpen,
  setCameraOpen,
  caregiverId,
  activeChat,
  dispatch,
}) => {
  const classes = useStyles()
  const [video, setVideo] = useState(null)
  const [error, setError] = useState("")
  const [isSendingVideo, setIsSendingVideo] = useState(false)
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl,
  } = useReactMediaRecorder({
    video: {
      width: { ideal: 400 },
      height: { ideal: 400 },
    },
    mediaRecorderOptions: { videoBitsPerSecond: 550000 },
    onStop: (blobUrl, blob) => {
      const file = new File([blob], `${blobUrl}.mp4`, {
        type: blob.type,
      })
      setVideo(file)
    },
  })

  const renderLoading = () => (
    <Box className={classes.loading}>
      <CircularProgress size={50} color="primary" />
      {isSendingVideo && (
        <Typography className={classes.loadingText} variant="subtitle">
          Stäng ej rutan, din video laddas upp...
        </Typography>
      )}
    </Box>
  )

  const renderTitle = () => {
    if (status === "acquiring_media") {
      return "Laddar kamera"
    }
    if (status === "idle") {
      return "Spela in video till din klient"
    }
    if (status === "recording") {
      return "Spelar in video..."
    }
    if (status === "stopped") {
      return "Vill du skicka videon till klienten?"
    }
  }

  const sendVideo = (videoUrl) =>
    new Promise(() => {
      if (!isEmpty(videoUrl)) {
        const date = new Date()
        dispatch(
          postMessage(activeChat.chatId, {
            userId: caregiverId,
            data: videoUrl,
            dataType: "VIDEO",
            fromUserId: caregiverId,
            toUserId: activeChat.userId,
            createdAt: date.toISOString(),
            seen: false,
            sessionId: activeChat.sessionId,
          })
        )
          .then(() => {
            setVideo(null)
            setIsSendingVideo(false)
            setCameraOpen(false)
            sessionStorage.setItem(
              `chat${activeChat.chatId}`,
              JSON.stringify("")
            )
          })
          .catch((err) => setError(err))
      }
    })

  const uploadVideo = async () => {
    try {
      setIsSendingVideo(true)
      const response = await videoUpload(video)
      sendVideo(response.data.url)
      setError("")
    } catch (err) {
      setError(err.response)
    }
  }

  useEffect(() => {
    if (status === "recording") {
      const timeout = setTimeout(() => {
        stopRecording()
      }, 60000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [status])

  return (
    <Drawer
      anchor="bottom"
      open={isCameraOpen}
      onClose={() => setCameraOpen(false)}
      className={classes.root}
    >
      <Box className={classes.closeButtonContainer}>
        <Button
          className={classes.closeButton}
          onClick={() => {
            stopRecording()
            setCameraOpen(false)
            clearBlobUrl()
          }}
        >
          <Close />
        </Button>
      </Box>
      <Box className={classes.title}>
        <Typography className={classes.titleText} align="center" variant="h6">
          {isSendingVideo ? "Skickar video" : renderTitle()}
        </Typography>
        {status === "idle" && (
          <Typography className={classes.titleText} align="center" variant="h6">
            (max 1 min)
          </Typography>
        )}
        {error && (
          <Typography
            className={classes.errorText}
            align="center"
            variant="subtitle"
          >
            {error}
          </Typography>
        )}
      </Box>
      {status === "acquiring_media" || isSendingVideo ? (
        renderLoading()
      ) : (
        <Box className={classes.videoContainer}>
          {status === "recording" && <Timer duration={59} />}
          {status === "idle" && (
            <Button
              className={classes.startRecordingButton}
              onClick={startRecording}
            >
              <FiberManualRecord />
            </Button>
          )}
          {status === "recording" && <VideoPreview stream={previewStream} />}
          {status === "stopped" && (
            <video
              title="test"
              src={mediaBlobUrl}
              height={250}
              width={250}
              controls
              autoPlay
              muted
              playsInline
            >
              <track kind="captions" />
            </video>
          )}
        </Box>
      )}
      <Box className={classes.buttonContainer}>
        {status === "recording" && (
          <Button className={classes.stopButtton} onClick={stopRecording}>
            <Stop />
          </Button>
        )}
        {status === "stopped" && !isSendingVideo && (
          <Box>
            <Button
              className={classes.drawerButton}
              onClick={() => {
                clearBlobUrl()
                setCameraOpen(false)
              }}
            >
              <Delete />
            </Button>
            <Button className={classes.drawerButton} onClick={uploadVideo}>
              <Check />
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  )
}

const mapStateToProps = ({ user, chats }) => {
  const { userId: caregiverId } = user
  const { activeChat } = chats

  return {
    caregiverId,
    activeChat,
  }
}

export default connect(mapStateToProps)(CameraDrawer)
