import React, { useState } from "react"
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { format } from "date-fns"
import { sv } from "date-fns/locale"

const useStyles = makeStyles((theme) => ({
  timeslotSection: {
    height: "80%",
    margin: `${theme.spacing(0.5)} auto`,
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
  timeSelector: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(-1),
  },
  timeslotContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(1.25),
    "& button": {
      "&:hover, &:active, &:focus": {
        background: theme.palette.primary.main,
        color: theme.palette.white,
      },
    },
  },
  timeslotButton: {
    borderRadius: theme.spacing(2),
    width: "30%",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    lineHeight: "2.5",
  },
  timeslotDate: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 2),
    },
  },
}))

const TimeSlot = ({ date, onTimeSlotSelect }) => {
  const classes = useStyles()
  return (
    <Button
      variant="outlined"
      className={classes.timeslotButton}
      onClick={onTimeSlotSelect}
    >
      {format(new Date(date), "HH:mm")}
    </Button>
  )
}

const AvailableTimeSlots = ({ timeSlots, setSelectedTimeSlot }) => {
  const classes = useStyles()
  const [dateIndex, setDateIndex] = useState(0)
  const [isIncrementDisabled, setIsIncrementDisabled] = useState(
    timeSlots.length - 1 === 0
  )
  const [isDecrementDisabled, setIsDecrementDisabled] = useState(true)
  const timezoneOffset = new Date(
    timeSlots[dateIndex]?.dayDate
  ).getTimezoneOffset()
  let timezoneOffsetMinutes
  let timezoneOffsetHours
  let timezoneOffsetSign
  if (timezoneOffset) {
    timezoneOffsetSign = timezoneOffset < 0 ? "+" : "-"
    const absoluteTimezoneOffset = Math.abs(timezoneOffset)
    timezoneOffsetMinutes = absoluteTimezoneOffset % 60
    timezoneOffsetHours = Math.floor(absoluteTimezoneOffset / 60)
  }

  const incrementDate = () => {
    const newIndex = dateIndex + 1
    setDateIndex(newIndex)
    setIsIncrementDisabled(newIndex === timeSlots.length - 1)
    setIsDecrementDisabled(false)
  }

  const decrementDate = () => {
    const newIndex = dateIndex - 1
    setDateIndex(newIndex)
    setIsDecrementDisabled(newIndex === 0)
    setIsIncrementDisabled(false)
  }

  return (
    <Box className={classes.timeslotSection}>
      <Box className={classes.timeSelector}>
        <Button disabled={isDecrementDisabled} onClick={decrementDate}>
          <KeyboardArrowLeftIcon />
        </Button>
        <Typography fontWeight="bold" className={classes.timeslotDate}>
          {format(new Date(timeSlots[dateIndex]?.dayDate), "EEEE d MMMM yyyy", {
            locale: sv,
          })}
        </Typography>
        <Button disabled={isIncrementDisabled} onClick={incrementDate}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
      <Typography variant="caption" sx={{ fontWeight: 500 }}>
        Tidszon: UTC
        {timezoneOffset
          ? `${timezoneOffsetSign}${timezoneOffsetHours}${
              timezoneOffsetMinutes ? `:${timezoneOffsetMinutes}` : ""
            }`
          : ""}
      </Typography>
      <Box className={classes.timeslotContainer}>
        {timeSlots[dateIndex]?.slots?.map((date, index) => (
          <TimeSlot
            key={index}
            date={date?.start}
            onTimeSlotSelect={() =>
              setSelectedTimeSlot({
                start: date?.start,
                end: date?.end,
                timeZone: date?.timeZone,
              })
            }
          />
        ))}
      </Box>
    </Box>
  )
}

export default AvailableTimeSlots
