import React, { memo, useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { useSessionStorage } from "utils/useSessionStorage"
import "emoji-mart/css/emoji-mart.css"
import { Picker } from "emoji-mart"
import {
  Box,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Fade,
  useMediaQuery,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
  Send as SendIcon,
  SendOutlined as SendOutlinedIcon,
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
  CancelOutlined as CancelOutlinedIcon,
  SentimentSatisfiedAlt as SentimentSatisfiedIcon,
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
  Videocam,
} from "@mui/icons-material/"
import { postMessage, showAlert } from "redux/actions"
import { getKaddioFormLink } from "api/getKaddioFormLink"
import { postSharedMaterialToKaddioContact } from "api/postSharedMaterialToKaddioContact"
import isEmpty from "lodash.isempty"
import { BrowserView, isDesktop, isIOS } from "react-device-detect"
import { useTheme } from "@emotion/react"
import { dataType } from "utils/dataType"
import MaterialPicker from "components/MaterialPicker"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    padding: theme.spacing(2),
    alignItems: "center",
    backgroundColor: theme.palette.lighter_gray,
    borderTop: `0.5px solid ${theme.palette.gray}`,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  input: {
    display: "flex",
    flexGrow: 1,
    borderRadius: 12.5,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    lineHeight: 1.4,
    border: `0.5px solid ${theme.palette.gray}`,
    fontFamily: `${theme.typography.fontFamily}`,
    fontWeight: 400,
    fontSize: 16,
    "&:focus": {
      outline: "none",
    },
    resize: "none",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 145px)",
    },
  },
  sendButton: {
    padding: 0,
    marginLeft: theme.spacing(2),
    "& svg": {
      fontSize: 35,
      color: theme.palette.primary.main,
    },
    "& svg:first-child": {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      "&:hover, &:active, &:focus": {
        backgroundColor: "transparent",
        "& svg:first-child": {
          display: "block",
        },
        "& svg:not(:first-child)": {
          display: "none",
        },
      },
    },
    "&:disabled": {
      "& svg": {
        color: theme.palette.gray,
      },
      "& svg:first-child": {
        display: "none !important",
      },
      "& svg:not(:first-child)": {
        display: "block !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  materialsButton: {
    padding: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0.25),
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& svg": {
      fontSize: "30px",
      opacity: "0.8",
      "&:hover": {
        opacity: "1",
      },
    },
    "&:disabled svg": {
      color: theme.palette.gray,
      opacity: 1,
    },
  },
  emojiContainer: {
    position: "relative",
  },
  emojiPicker: {
    right: "-60px",
    bottom: "46px",
    position: "absolute",
  },
  emojiButton: {
    padding: "7px 5px 5px",
    minWidth: "30px",
    marginLeft: theme.spacing(2),
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& svg": { fontSize: "28px", color: theme.palette.primary.main },
    "&:disabled svg": { color: theme.palette.gray },
  },
  cameraButton: {
    color: theme.palette.primary.main,
    position: "relative",
    "& svg": {
      fontSize: "30px",
    },
  },
  "@global": {
    ".emoji-mart": {
      "&:after": {
        content: "''",
        width: "15px",
        height: "15px",
        transform: "rotate(-45deg)",
        background: "#fff",
        position: "absolute",
        bottom: "-8px",
        right: "71px",
        borderBottom: "1px solid #d9d9d9",
        borderLeft: "1px solid #d9d9d9",
      },
      "& .emoji-mart-scroll": {
        height: "285px",
      },
    },
    ".emoji-mart-preview": {
      display: "none",
    },
    ".emoji-mart-bar:last-child": {
      minHeight: "10px",
    },
  },
}))

const resolver = (message, context) => {
  const { disableChat } = context

  return {
    values: disableChat ? {} : message,
    errors: disableChat
      ? {
          message: "archived",
        }
      : {},
  }
}

const ChatBar = ({
  dispatch,
  caregiverId,
  caregiverKaddioId,
  activeChat,
  kaddioForms,
  setCameraOpen,
  switchChatLoading,
  patientFullName,
}) => {
  const classes = useStyles()
  const {
    userId: patientId,
    userKaddioId: patientKaddioId,
    userEmail: patientEmail,
    userKaddioContactId: patientKaddioContactId,
    userIsSormland: patientIsSormland,
    chatId: activeChatId,
    unmatched,
    sessionId: activeChatSessionId,
  } = activeChat || {}
  const disableChat = !activeChatId || switchChatLoading || unmatched
  const { control, handleSubmit, setValue, reset, register, formState } =
    useForm({
      reValidateMode: "onSubmit",
      resolver,
      context: { disableChat },
    })
  const [inputMessage, setInputMessage] = useSessionStorage(
    `chat${activeChatId}`
  )
  setValue("message", inputMessage)
  const [openMaterialPicker, setOpenMaterialPicker] = useState(false)
  const [selectedMaterial, setSelectedMaterial] = useState(null)
  const [anchorMaterialEl, setAnchorMaterialEl] = useState(null)
  const [isHoveredEmojiButton, setIsHoveredEmojiButton] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [placeholder, setPlaceholder] = useState("")
  const messageRef = useRef(null)
  const { ref } = register("message")
  const emojiPickerRef = useRef(null)
  const emojiButtonRef = useRef(null)
  const [cursorPosition, setCursorPosition] = useState(null)
  const { isSubmitting } = formState
  const theme = useTheme()
  const isWidthSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    if (isDesktop) {
      const handleClickOutsideEmojiPicker = (event) => {
        const isClickInsideEmojiPicker =
          emojiPickerRef.current &&
          emojiPickerRef.current.contains(event.target)
        const isEmojiButtonClick =
          emojiButtonRef.current &&
          emojiButtonRef.current.contains(event.target)
        if (!isClickInsideEmojiPicker && !isEmojiButtonClick) {
          setShowEmojiPicker(false)
          setIsHoveredEmojiButton(false)
        }
      }
      document.addEventListener("click", handleClickOutsideEmojiPicker)
      return () => {
        document.removeEventListener("click", handleClickOutsideEmojiPicker)
      }
    }
  }, [])

  useEffect(() => {
    if (isSubmitting) {
      reset()
    }
    if (activeChatId) {
      setPlaceholder(
        unmatched
          ? "Patient matchad med annan psykolog."
          : "Skriv ett meddelande..."
      )
    }
  }, [activeChatId])

  const handleOpenMaterialPicker = (event) => {
    setAnchorMaterialEl(event.currentTarget)
    setOpenMaterialPicker(true)
  }

  const handleKaddioFormNotGeneratedError = (errorMessage) => {
    dispatch(showAlert({ type: "error", message: errorMessage }))
    setSelectedMaterial(null)
  }

  const handleCloseMaterialPicker = async (material) => {
    const date = new Date()
    setOpenMaterialPicker(false)
    if (material?.id) {
      if (!patientKaddioId) {
        handleKaddioFormNotGeneratedError(
          // eslint-disable-next-line max-len
          "Formuläret kunde tyvärr inte skapas för patienten saknas i Kaddio. Om problemen består, kontakta info@dinpsykolog.se."
        )
      } else {
        const { data } = await getKaddioFormLink(
          caregiverKaddioId,
          patientKaddioId,
          material.id,
          patientId
        )

        if (data) {
          dispatch(
            postMessage(activeChatId, {
              caregiverId,
              data: `${data}#${material.description.replace(/ /g, "&")}`,
              dataType: dataType.TEXT,
              fromUserId: caregiverId,
              toUserId: patientId,
              createdAt: date.toISOString(),
              seen: false,
              sessionId: activeChatSessionId,
            })
          ).then(() => {
            setSelectedMaterial(null)
          })
        } else {
          handleKaddioFormNotGeneratedError(
            // eslint-disable-next-line max-len
            "Formuläret kunde inte genereras. Vänligen försök igen inom kort. Om problemen består, kontakta info@dinpsykolog.se."
          )
        }
      }
    } else if (material?.url) {
      dispatch(
        postMessage(activeChatId, {
          caregiverId,
          data: material.url,
          dataType: dataType.TEXT,
          fromUserId: caregiverId,
          toUserId: patientId,
          createdAt: date.toISOString(),
          seen: false,
          sessionId: activeChatSessionId,
        })
      ).then(() => {
        setSelectedMaterial(null)
        if (caregiverKaddioId && patientKaddioContactId) {
          dispatch(
            postSharedMaterialToKaddioContact(
              caregiverKaddioId,
              patientKaddioContactId,
              {
                materialName: material.description,
                sessionId: activeChatSessionId,
              }
            )
          )
        }
      })
    }
  }

  const changeInputMessage = (message) => {
    setInputMessage(message)
    setValue("message", message)
    if (activeChatId) {
      sessionStorage.setItem(`chat${activeChatId}`, JSON.stringify(message))
    }
  }

  const handleNewFileUploaded = (url) => {
    let currentValue = messageRef.current.value
    currentValue &&= `${messageRef.current.value} `
    changeInputMessage(`${currentValue}${url}`)
  }

  const onSubmit = (data) =>
    new Promise(() => {
      const { message } = data

      if (!isIOS) {
        messageRef.current.focus()
      }

      if (!isEmpty(message.trim())) {
        const date = new Date()

        dispatch(
          postMessage(activeChatId, {
            caregiverId,
            data: message,
            dataType: dataType.TEXT,
            fromUserId: caregiverId,
            toUserId: patientId,
            createdAt: date.toISOString(),
            seen: false,
            sessionId: activeChatSessionId,
          })
        ).then(() => {
          changeInputMessage("")
          reset({ message: "" })
          sessionStorage.setItem(`chat${activeChatId}`, JSON.stringify(""))
        })
      }
    })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.root}>
        <IconButton
          className={classes.materialsButton}
          onClick={handleOpenMaterialPicker}
          disabled={disableChat}
        >
          {openMaterialPicker ? (
            <CancelOutlinedIcon color="primary" />
          ) : (
            <Tooltip
              arrow
              title="Skicka material"
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 100 }}
            >
              <AddCircleOutlineOutlinedIcon color="primary" />
            </Tooltip>
          )}
        </IconButton>
        <MaterialPicker
          selectedMaterial={selectedMaterial}
          openPicker={openMaterialPicker}
          onClose={handleCloseMaterialPicker}
          anchorEl={anchorMaterialEl}
          kaddioForms={kaddioForms}
          onNewFileUploaded={handleNewFileUploaded}
          chatId={activeChatId}
          caregiverId={caregiverId}
          patientId={patientId}
          patientFullName={patientFullName}
          patientEmail={patientEmail}
          patientIsSormland={patientIsSormland}
          includeFileUploadAndInstantMeeting
        />
        <Controller
          name="message"
          defaultValue={inputMessage}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextareaAutosize
              className={classes.input}
              placeholder={placeholder}
              autoComplete="off"
              minRows={1}
              maxRows={8}
              maxLength={5000}
              disabled={disableChat}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && (e.metaKey || e.ctrlKey)) {
                  handleSubmit(onSubmit)()
                }
              }}
              value={field.value}
              ref={(e) => {
                ref(e)
                messageRef.current = e
              }}
              onChange={(e) => {
                changeInputMessage(e.target.value)
                field.onChange(e)
              }}
              onBlur={() => {
                setCursorPosition(messageRef.current.selectionStart)
              }}
            />
          )}
        />
        <BrowserView>
          <div className={classes.emojiContainer}>
            <IconButton
              disabled={disableChat}
              ref={emojiButtonRef}
              onMouseEnter={() => setIsHoveredEmojiButton(true)}
              onMouseLeave={() => setIsHoveredEmojiButton(showEmojiPicker)}
              id="emoji-btn"
              className={classes.emojiButton}
              onClick={() => {
                setShowEmojiPicker(!showEmojiPicker)
              }}
            >
              {isHoveredEmojiButton ? (
                <SentimentVerySatisfiedIcon />
              ) : (
                <SentimentSatisfiedIcon />
              )}
            </IconButton>
            {showEmojiPicker && (
              <Box className={classes.emojiPicker} ref={emojiPickerRef}>
                <Picker
                  exclude={["flags"]}
                  enableFrequentEmojiSort
                  emojiTooltip
                  showPreview={false}
                  onSelect={(emoji) => {
                    if (typeof cursorPosition === "number") {
                      changeInputMessage(
                        inputMessage.substring(0, cursorPosition) +
                          emoji.native +
                          inputMessage.substring(cursorPosition)
                      )
                      setCursorPosition(cursorPosition + emoji.native.length)
                    } else {
                      changeInputMessage(inputMessage + emoji.native)
                    }
                  }}
                />
              </Box>
            )}
          </div>
        </BrowserView>
        {isWidthSmDown && (
          <IconButton
            disabled={disableChat}
            className={classes.cameraButton}
            onClick={() => setCameraOpen(true)}
          >
            <Videocam />
          </IconButton>
        )}
        <IconButton
          type="submit"
          className={classes.sendButton}
          disabled={
            disableChat || isEmpty(inputMessage?.trim()) || isSubmitting
          }
        >
          <SendOutlinedIcon />
          <SendIcon />
        </IconButton>
      </Box>
    </form>
  )
}

const mapStateToProps = ({ user, chats, kaddioForms }) => {
  const { userId: caregiverId, kaddioId: caregiverKaddioId } = user
  const { activeChat } = chats

  return {
    caregiverId,
    caregiverKaddioId,
    activeChat,
    kaddioForms,
  }
}

export default memo(connect(mapStateToProps)(ChatBar))
