import React from "react"
import { connect } from "react-redux"
import { Snackbar, Alert as MuiAlert } from "@mui/material/"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  increasedFont: {
    "& .MuiAlert-message > *": {
      fontSize: "1.1rem",
      fontWeight: 600,
    },
    "& .MuiAlert-icon > *": {
      fontSize: "1.625rem",
    },
  },
  "@global": {
    ".MuiSnackbar-root .MuiAlert-action": {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(0.75),
      },
    },
  },
}))

const Alert = ({ alert, dispatch }) => {
  const classes = useStyles()
  if (alert) {
    const { type, message, increasedFont, show, hide, duration } = alert

    return (
      <Snackbar
        open={show}
        onClose={() => dispatch(hide())}
        autoHideDuration={duration}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={type}
          color={type}
          className={increasedFont ? classes.increasedFont : ""}
          onClose={() => dispatch(hide())}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    )
  }
  return null
}

function mapStateToProps({ alert }) {
  return { alert }
}

export default connect(mapStateToProps)(Alert)
