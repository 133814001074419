import Api from "api"

/**
 * Marks a patient sessions as sessions with given feedback.
 *
 * @param {string} patientId
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const updateIkbtFeedback = (patientId) =>
  Api.put("/users/caregiver/ikbt/updatePatientSessionFeedback", {
    patientId,
  })
