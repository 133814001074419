import Api from "api"

export const fileUpload = (file) => {
  const bodyFormData = new FormData()
  bodyFormData.append("chatFile", file)

  return Api.post("users/uploads", bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
