import Api from "api"

export const getKaddioFormLink = (
  kaddioCaregiverUserId,
  kaddioPatientUserId,
  kaddioFormId,
  patientId
) =>
  Api.get(
    // eslint-disable-next-line max-len
    `/users/caregiver/kaddio/${kaddioCaregiverUserId}/form/${kaddioPatientUserId}/${kaddioFormId}/link?patientId=${patientId}`
  )
