import React, { useState, useEffect, useMemo } from "react"
import { anonymousModeContext as AnonymousModeContext } from "contexts"

const LocalStorageListenerWrapper = ({ children }) => {
  const [anonymousModeValue, setAnonymousModeValue] = useState(
    localStorage.getItem("anonymousMode") === "true"
  )

  useEffect(() => {
    if (!localStorage.getItem("anonymousMode")) {
      localStorage.setItem("anonymousMode", false)
    }

    const checkViewMode = () => {
      const mode = localStorage.getItem("anonymousMode")

      setAnonymousModeValue(mode === "true")
    }
    window.addEventListener("storage", checkViewMode)

    return () => {
      window.removeEventListener("storage", checkViewMode)
    }
  }, [anonymousModeValue])

  const toggleAnonymousContext = () => {
    setAnonymousModeValue(!anonymousModeValue)
  }

  const anonymousMode = useMemo(
    () => [anonymousModeValue, toggleAnonymousContext],
    [anonymousModeValue]
  )

  return (
    <AnonymousModeContext.Provider value={anonymousMode}>
      {children}
    </AnonymousModeContext.Provider>
  )
}

export default LocalStorageListenerWrapper
