/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from "react"
import {
  Popover,
  DialogTitle,
  DialogContent,
  Button,
  Tooltip,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { TreeView, TreeItem } from "@mui/lab"
import { connect } from "react-redux"
import { showAlert } from "redux/actions"
import {
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
  Link as LinkIcon,
  AttachFile as AttachFileIcon,
  VideoCall,
} from "@mui/icons-material/"
import materials from "utils/materials"
import VideoMeetingModal from "components/VideoMeetingModal"
import halsaHemmaLogo from "assets/halsa_hemma_logo.png"
import { checkPatientHalsaHemmaEligibility } from "api/checkPatientHalsaHemmaEligibility"
import { registerPatientForHalsaHemma } from "api/registerPatientForHalsaHemma"
import { invitePatientToIkbt } from "api/invitePatientToIkbt"
import braiveLogo from "assets/braive_logo.svg"
import IkbtDialog from "./IkbtDialog"
import { uploadFile } from "../redux/actions"
import MaterialPreviewDialog from "./MaterialPreviewDialog"
import HalsaHemmaDialog from "./HalsaHemmaDialog"

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  buttonItem: {
    marginLeft: "7px",
    borderRadius: "0",
    width: "286px",
    fontWeight: "400",
    lineHeight: "24px",
    fontSize: "16px",
    textTransform: "none",
    justifyContent: "start",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    color: theme.palette.black,
  },
  content: ({ patientIsSormland, patientEligibleForHalsaHemma }) => ({
    width: "300px",
    height: patientIsSormland
      ? patientEligibleForHalsaHemma
        ? "270px"
        : "230px"
      : patientEligibleForHalsaHemma
      ? "310px"
      : "270px",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "8px 0 8px 7px",
    "& ul": { overflow: "hidden", height: "auto" },
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(0.75),
      marginLeft: theme.spacing(-0.75),
    },
  }),
  treeItem: {
    paddingLeft: "5px",
    "&:hover": {
      backgroundColor: "rgba(53,115,118,0.3)",
    },
  },
  "@global": {
    "#button-file": {
      display: "none",
    },
    ".MuiButton-startIcon": {
      marginRight: "4px",
    },
    ".MuiTreeView-root": {
      paddingLeft: "7px",
    },
    ".MuiTreeItem-label": {
      padding: "10px 0 10px 9px",
    },
    ".MuiPaper-root .MuiAccordionSummary-content": {
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        margin: "5px 0",
      },
    },
  },
}))

const MaterialPicker = ({
  dispatch,
  onClose,
  selectedMaterial,
  openPicker,
  anchorEl,
  kaddioForms,
  onNewFileUploaded,
  chatId,
  patientFullName,
  patientIsSormland,
  caregiverId,
  includeFileUploadAndInstantMeeting,
  patientId,
}) => {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false)
  const [isIkbtModalOpen, setIkbtModalOpen] = useState(false)
  const [materialPreviewDialogItem, setMaterialPreviewDialogItem] = useState({})
  const [openMaterialPreviewDialog, setOpenMaterialPreviewDialog] =
    useState(false)
  const [anchorHalsaHemmaEl, setAnchorHalsaHemmaEl] = useState(null)
  const [openHalsaHemmaDialog, setOpenHalsaHemmaDialog] = useState(false)
  const [patientEligibleForHalsaHemma, setPatientEligibleForHalsaHemma] =
    useState(false)

  const handleCloseMaterialPicker = () => {
    onClose(selectedMaterial)
  }

  const checkHalsaHemmaEligibility = async () => {
    const { data: isPatientEligibleForHalsaHemma } =
      await checkPatientHalsaHemmaEligibility(patientId)
    setPatientEligibleForHalsaHemma(isPatientEligibleForHalsaHemma)
  }

  const handleRegisterPatientForHalsaHemma = (event) => {
    registerPatientForHalsaHemma(patientId)
      .then(() => {
        setOpenHalsaHemmaDialog(false)
        setPatientEligibleForHalsaHemma(false)
        dispatch(
          showAlert({
            type: "success",
            // eslint-disable-next-line max-len
            message: `Du hänvisade patient ${patientFullName} till Hälsa Hemma. De kommer höra av sig till patienten via telefon.`,
          })
        )
      })
      .catch(() => {
        event.target.disabled = false
        dispatch(
          showAlert({
            type: "error",
            message: "Patienten kunde tyvärr inte hänvisas.",
          })
        )
      })
  }

  const handleInvitePatientToIkbt = (event) => {
    invitePatientToIkbt(patientId)
      .then(() => {
        dispatch(
          showAlert({
            type: "success",
            // eslint-disable-next-line max-len
            message: "Patienten har nu fått en inbjudningslänk till sin email!",
          })
        )
        setIkbtModalOpen(false)
      })
      .catch(() => {
        event.target.disabled = false
        dispatch(
          showAlert({
            type: "error",
            message:
              "Det gick tyvärr inte att bjuda in patienten. Vänligen försök igen!",
          })
        )
        setIkbtModalOpen(false)
      })
  }

  const handleNewFileUploaded = (file) => {
    dispatch(uploadFile(file)).then((url) => {
      onClose()
      if (url) onNewFileUploaded(url)
    })
  }

  const handleMaterialItemClick = (material) => {
    setMaterialPreviewDialogItem(material)
    setOpenMaterialPreviewDialog(true)
  }

  const handleKaddioFormClick = (formId, formName, formUrl) => {
    setMaterialPreviewDialogItem({
      id: formId,
      description: formName,
      url: formUrl,
    })
    setOpenMaterialPreviewDialog(true)
  }

  const handleCloseMaterialPreviewDialog = () => {
    setOpenMaterialPreviewDialog(false)
    setMaterialPreviewDialogItem({})
  }

  const handleSendMaterial = () => {
    onClose(materialPreviewDialogItem)
    handleCloseMaterialPreviewDialog()
  }

  const handleOpenHalsaHemmaDialog = (event) => {
    setAnchorHalsaHemmaEl(event.currentTarget)
    setOpenHalsaHemmaDialog(true)
  }

  const kaddioFormularRef = useRef(null)
  const [isKaddioFormularOpen, setKaddioFormularOpen] = useState(false)

  useEffect(() => {
    if (patientId) {
      checkHalsaHemmaEligibility()
    }
  }, [patientId])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isKaddioFormularOpen) {
        kaddioFormularRef.current.scrollIntoView({ behavior: "smooth" })
      }
    }, 100)

    return () => clearTimeout(timeout)
  }, [isKaddioFormularOpen])

  const classes = useStyles({ patientIsSormland, patientEligibleForHalsaHemma })
  return (
    <>
      {isVideoModalOpen && (
        <VideoMeetingModal
          open={isVideoModalOpen}
          chatId={chatId}
          setVideoModalOpen={setVideoModalOpen}
        />
      )}
      {isIkbtModalOpen && (
        <IkbtDialog
          open={isIkbtModalOpen}
          onClose={() => setIkbtModalOpen(false)}
          handleInvitePatientToIkbt={handleInvitePatientToIkbt}
        />
      )}
      <HalsaHemmaDialog
        openDialog={openHalsaHemmaDialog}
        onClose={() => setOpenHalsaHemmaDialog(false)}
        anchorEl={anchorHalsaHemmaEl}
        onRegisterPatientForHalsaHemma={handleRegisterPatientForHalsaHemma}
      />
      <Popover
        open={openPicker}
        onClose={handleCloseMaterialPicker}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DialogTitle className={classes.title}>Skicka material</DialogTitle>
        <DialogContent className={classes.content}>
          {
            // show file upload button only for Felicia
            caregiverId === "e5c3df08-cf34-4b5d-8d7f-5022b7e28e52" &&
              includeFileUploadAndInstantMeeting && (
                <label htmlFor="button-file">
                  <input
                    accept="image/*,.pdf,.xlsx,.docx"
                    onInput={({
                      target: {
                        files: [file],
                      },
                    }) => handleNewFileUploaded(file)}
                    id="button-file"
                    type="file"
                  />
                  <Button
                    variant="text"
                    size="large"
                    className={classes.buttonItem}
                    component="div"
                    startIcon={<AttachFileIcon />}
                  >
                    Skicka fil
                  </Button>
                </label>
              )
          }
          {
            // show instant meeting button only for Felicia
            caregiverId === "e5c3df08-cf34-4b5d-8d7f-5022b7e28e52" &&
              includeFileUploadAndInstantMeeting && (
                <Button
                  variant="text"
                  size="large"
                  className={classes.buttonItem}
                  component="div"
                  startIcon={<VideoCall />}
                  onClick={() => setVideoModalOpen(true)}
                >
                  Create Instant Meeting
                </Button>
              )
          }
          <TreeView
            aria-label="materials menu"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
          >
            {materials.models.length > 0 && (
              <TreeItem nodeId="1" label="Modeller">
                {materials.models.map((model, index) => (
                  <TreeItem
                    onClick={() => handleMaterialItemClick(model)}
                    key={`1_${index}`}
                    className={classes.treeItem}
                    label={model.description}
                    endIcon={<LinkIcon />}
                    nodeId={`1_${index}`}
                  />
                ))}
              </TreeItem>
            )}
            {materials.psychoeducations.length > 0 && (
              <TreeItem nodeId="2" label="Psykoedukation">
                {materials.psychoeducations.map((psychoeducation, index) => (
                  <TreeItem
                    onClick={() => handleMaterialItemClick(psychoeducation)}
                    key={`2_${index}`}
                    className={classes.treeItem}
                    label={psychoeducation.description}
                    endIcon={<LinkIcon />}
                    nodeId={`2_${index}`}
                  />
                ))}
              </TreeItem>
            )}
            {Object.values(materials.treatmentPrograms).some(
              (treatmentProgram) => treatmentProgram.length > 0
            ) && (
              <TreeItem nodeId="3" label="Behandlingsprogram">
                {materials.treatmentPrograms.depressionProgram.length > 0 && (
                  <TreeItem nodeId="4" label="Depression">
                    {materials.treatmentPrograms.depressionProgram.map(
                      (depressionProgram, index) => (
                        <TreeItem
                          onClick={() =>
                            handleMaterialItemClick(depressionProgram)
                          }
                          key={`4_${index}`}
                          className={classes.treeItem}
                          label={depressionProgram.description}
                          endIcon={<LinkIcon />}
                          nodeId={`4_${index}`}
                        />
                      )
                    )}
                  </TreeItem>
                )}
                {materials.treatmentPrograms.emotionallyFocusedPrograms.length >
                  0 && (
                  <TreeItem nodeId="5" label="Känslofokuserat">
                    {materials.treatmentPrograms.emotionallyFocusedPrograms.map(
                      (emotionallyFocusedProgram, index) => (
                        <TreeItem
                          onClick={() =>
                            handleMaterialItemClick(emotionallyFocusedProgram)
                          }
                          key={`5_${index}`}
                          className={classes.treeItem}
                          label={emotionallyFocusedProgram.description}
                          endIcon={<LinkIcon />}
                          nodeId={`5_${index}`}
                        />
                      )
                    )}
                  </TreeItem>
                )}
                {materials.treatmentPrograms.specificPhobiaProgram.length >
                  0 && (
                  <TreeItem nodeId="6" label="Specifik fobi">
                    {materials.treatmentPrograms.specificPhobiaProgram.map(
                      (specificPhobiaProgram, index) => (
                        <TreeItem
                          onClick={() =>
                            handleMaterialItemClick(specificPhobiaProgram)
                          }
                          key={`6_${index}`}
                          className={classes.treeItem}
                          label={specificPhobiaProgram.description}
                          endIcon={<LinkIcon />}
                          nodeId={`6_${index}`}
                        />
                      )
                    )}
                  </TreeItem>
                )}
                {materials.treatmentPrograms.stressProgram.length > 0 && (
                  <TreeItem nodeId="7" label="Stress">
                    {materials.treatmentPrograms.stressProgram.map(
                      (stressProgram, index) => (
                        <TreeItem
                          onClick={() => handleMaterialItemClick(stressProgram)}
                          key={`7_${index}`}
                          className={classes.treeItem}
                          label={stressProgram.description}
                          endIcon={<LinkIcon />}
                          nodeId={`7_${index}`}
                        />
                      )
                    )}
                  </TreeItem>
                )}
                {materials.treatmentPrograms.sleepingProblemPrograms.length >
                  0 && (
                  <TreeItem nodeId="8" label="Sömnproblem">
                    {materials.treatmentPrograms.sleepingProblemPrograms.map(
                      (sleepingProblemProgram, index) => (
                        <TreeItem
                          onClick={() =>
                            handleMaterialItemClick(sleepingProblemProgram)
                          }
                          key={`8_${index}`}
                          className={classes.treeItem}
                          label={sleepingProblemProgram.description}
                          endIcon={<LinkIcon />}
                          nodeId={`8_${index}`}
                        />
                      )
                    )}
                  </TreeItem>
                )}
                {materials.treatmentPrograms.traumaPrograms.length > 0 && (
                  <TreeItem nodeId="9" label="Trauma">
                    {materials.treatmentPrograms.traumaPrograms.map(
                      (traumaProgram, index) => (
                        <TreeItem
                          onClick={() => handleMaterialItemClick(traumaProgram)}
                          key={`9_${index}`}
                          className={classes.treeItem}
                          label={traumaProgram.description}
                          endIcon={<LinkIcon />}
                          nodeId={`9_${index}`}
                        />
                      )
                    )}
                  </TreeItem>
                )}
              </TreeItem>
            )}
            {materials.cbtWorksheets.length > 0 && (
              <TreeItem nodeId="10" label="KBT-arbetsblad">
                {materials.cbtWorksheets.map((cbtWorksheet, index) => (
                  <TreeItem
                    onClick={() => handleMaterialItemClick(cbtWorksheet)}
                    key={`10_${index}`}
                    className={classes.treeItem}
                    label={cbtWorksheet.description}
                    endIcon={<LinkIcon />}
                    nodeId={`10_${index}`}
                  />
                ))}
              </TreeItem>
            )}
            {kaddioForms?.length > 0 && (
              <TreeItem
                onClick={() => {
                  setKaddioFormularOpen(!isKaddioFormularOpen)
                }}
                nodeId="11"
                label="Kaddio-formulär"
                ref={kaddioFormularRef}
              >
                {kaddioForms.map(({ _id, name, url }) => (
                  <TreeItem
                    onClick={() => handleKaddioFormClick(_id, name, url)}
                    key={_id}
                    className={classes.treeItem}
                    label={name}
                    endIcon={<LinkIcon />}
                    nodeId={_id}
                  />
                ))}
              </TreeItem>
            )}
          </TreeView>
          <Tooltip
            title={
              !!patientIsSormland
                ? "Patient från Sörmland. Kan ej bjudas in till IKBT."
                : ""
            }
            arrow
          >
            <span>
              <Button
                variant="text"
                size="large"
                className={classes.buttonItem}
                component="div"
                startIcon={
                  <img
                    src={braiveLogo}
                    alt="Braive logo"
                    width="20px"
                    height="20px"
                  />
                }
                onClick={() => setIkbtModalOpen(true)}
                disabled={!!patientIsSormland}
              >
                Bjud in patient till IKBT
              </Button>
            </span>
          </Tooltip>
          {patientEligibleForHalsaHemma && (
            <Button
              variant="text"
              size="large"
              component="div"
              disableRipple
              startIcon={<img alt="Hälsa Hemma logo" src={halsaHemmaLogo} />}
              onClick={handleOpenHalsaHemmaDialog}
              className={classes.buttonItem}
            >
              Hänvisa till läkare
            </Button>
          )}
        </DialogContent>
      </Popover>
      <MaterialPreviewDialog
        openDialog={openMaterialPreviewDialog}
        onClose={handleCloseMaterialPreviewDialog}
        onSend={handleSendMaterial}
        dialogItem={materialPreviewDialogItem}
      />
    </>
  )
}

export default connect(null)(MaterialPicker)
