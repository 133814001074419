import {
  Popover,
  DialogContent,
  Box,
  Typography,
  CircularProgress,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material"
import { connect, useDispatch } from "react-redux"
import { fetchAvailableTimeSlots } from "redux/actions"
import { makeStyles } from "@mui/styles"
import React, { useEffect, useState } from "react"
import { format } from "date-fns/"
import { sv } from "date-fns/locale"
import AvailableTimeSlots from "./AvailableTimeSlots"
import AddTimeSlot from "./AddTimeSlot"

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        display: "table",
        width: "95%",
      },
    },
  },
  content: {
    width: "500px",
    height: "235px",
    overflowY: "auto",
    overflowX: "auto",
    padding: theme.spacing(1),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "230px",
    },
  },
  centered: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },
  confirmContent: {
    textAlign: "left",
    padding: theme.spacing(2.5, 2),
  },
  confirmFooter: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: "0.8",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: "1",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      pointerEvents: "none",
    },
  },
  cancelButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  addTimeContainer: {
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(1),
  },
  addTimeText: {
    fontStyle: "italic",
    fontWeight: 500,
    fontSize: "0.8rem",
  },
  addTimeButton: {
    textTransform: "none",
    borderRadius: theme.spacing(12.5),
    padding: theme.spacing(0.5, 6.5),
    marginTop: theme.spacing(1.25),
    boxShadow: "none",
    fontSize: "0.9rem",
    lineHeight: 2.1,
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}))

const BookVideoMeetingDialog = ({
  openDialog,
  anchorEl,
  availableTimeSlots,
  patientFullName,
  meetingStart,
  onClose,
  onConfirmBooking,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isAddTimeSlotDisplayed, setIsAddTimeSlotDisplayed] = useState(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)

  useEffect(() => {
    if (openDialog) {
      if (!availableTimeSlots) {
        dispatch(fetchAvailableTimeSlots(meetingStart))
      }
      setIsAddTimeSlotDisplayed(false)
      setSelectedTimeSlot(null)
    }
  }, [openDialog])

  const renderNoAvailableTimes = () => (
    <Box className={classes.centered}>
      <Typography fontWeight="bold">Inga tillgängliga tider</Typography>
    </Box>
  )

  const renderLoading = () => (
    <Box className={classes.centered}>
      <CircularProgress className={classes.centered} size={40} />
    </Box>
  )

  const renderConfirmBooking = () => (
    <>
      <DialogContent className={classes.confirmContent}>
        Är du säker på att du vill boka tiden{" "}
        {`${format(new Date(selectedTimeSlot.start), "d MMM", {
          locale: sv,
        }).replace(/\./g, "")} ${format(
          new Date(selectedTimeSlot.start),
          "HH.mm",
          {
            locale: sv,
          }
        )} åt patient ${patientFullName}`}
        ?
      </DialogContent>
      <DialogActions className={classes.confirmFooter}>
        <Button
          onClick={() => setSelectedTimeSlot(null)}
          className={classes.cancelButton}
          color="default"
        >
          Avbryt
        </Button>
        <Button
          onClick={(e) => {
            e.target.disabled = true
            onConfirmBooking(selectedTimeSlot.start, selectedTimeSlot.timeZone)
          }}
          className={classes.confirmButton}
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </>
  )

  const renderContent = () => {
    if (!availableTimeSlots) {
      return renderLoading()
    }
    if (selectedTimeSlot) {
      return renderConfirmBooking()
    }
    if (isAddTimeSlotDisplayed) {
      return (
        <AddTimeSlot
          onClose={() => {
            setIsAddTimeSlotDisplayed(false)
            setSelectedTimeSlot(null)
          }}
          availableTimeSlots={availableTimeSlots}
          meetingStart={meetingStart}
          setSelectedTimeSlot={setSelectedTimeSlot}
        />
      )
    }
    if (availableTimeSlots?.length) {
      return (
        <AvailableTimeSlots
          timeSlots={availableTimeSlots}
          setSelectedTimeSlot={setSelectedTimeSlot}
        />
      )
    }
    return renderNoAvailableTimes()
  }

  return (
    <Popover
      open={openDialog}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      className={classes.contentContainer}
    >
      <DialogTitle
        sx={{
          fontSize: "1rem",
          padding: (theme) => theme.spacing(2, 6, 1),
          color: (theme) => theme.palette.primary.dark,
        }}
      >
        Boka tid åt {patientFullName}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {renderContent()}
      </DialogContent>
      {!isAddTimeSlotDisplayed && !selectedTimeSlot && (
        <DialogActions>
          <Box className={classes.addTimeContainer}>
            <Typography
              variant="caption"
              component="p"
              className={classes.addTimeText}
            >
              Vill du lägga till och boka en annan tid?
            </Typography>
            <Button
              variant="contained"
              className={classes.addTimeButton}
              onClick={() => {
                setIsAddTimeSlotDisplayed(true)
              }}
            >
              Boka annan tid
            </Button>
          </Box>
        </DialogActions>
      )}
    </Popover>
  )
}

const mapStateToProps = ({ availableTimeSlots }) => ({
  availableTimeSlots,
})

export default connect(mapStateToProps)(BookVideoMeetingDialog)
