/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { availabilityConfirmationDialogType } from "utils/availabilityConfirmationDialogType"
import { availabilityUpdateType as updateType } from "utils/availabilityUpdateType"

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  radio: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      marginRight: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "start",
      "& span": {
        paddingTop: 0,
      },
    },
  },
}))

const AvailabilityConfirmationDialog = ({
  onClose,
  openDialog,
  onDeleteAvailability,
  onUpdateAvailability,
  dialogType,
  isRecurringAvailability,
  isOwnBookingType,
}) => {
  const classes = useStyles()
  const availabilityTypeText = isOwnBookingType
    ? "egen bokning"
    : "tillgänglighet"
  const [availabilityUpdateType, setAvailabilityUpdateType] = useState(
    updateType.SINGLE
  )
  const isDeleteDialogType =
    dialogType === availabilityConfirmationDialogType.DELETE
  const nonRecurringAvailabilityUpdateType = updateType.ALL

  useEffect(() => {
    setAvailabilityUpdateType(updateType.SINGLE)
  }, [openDialog])

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      maxWidth={isDeleteDialogType && !isRecurringAvailability ? "xs" : "sm"}
    >
      <DialogTitle>
        {isDeleteDialogType ? (
          isRecurringAvailability ? (
            <>
              Radera återkommande {availabilityTypeText}
              {!isOwnBookingType && (
                <Typography
                  variant="caption"
                  component="p"
                  sx={(theme) => ({
                    color: theme.palette.warning.main,
                  })}
                >
                  OBS! Om du har bokningar under denna tiden som du vill avboka
                  behöver du avboka dessa manuellt var för sig.
                </Typography>
              )}
            </>
          ) : (
            <>
              Radera {availabilityTypeText}
              {!isOwnBookingType && (
                <Typography
                  variant="caption"
                  component="p"
                  sx={(theme) => ({
                    color: theme.palette.warning.main,
                  })}
                >
                  OBS! Om du har bokningar under denna tiden som du vill avboka
                  behöver du avboka dessa manuellt var för sig.
                </Typography>
              )}
            </>
          )
        ) : (
          "Uppdatera återkommande tillgänglighet"
        )}
      </DialogTitle>
      <DialogContent>
        {isRecurringAvailability ? (
          <RadioGroup
            defaultValue={updateType.SINGLE}
            name="recurring-availability-radio-group"
            value={availabilityUpdateType}
            onChange={(event) => setAvailabilityUpdateType(event.target.value)}
          >
            <FormControlLabel
              value={updateType.SINGLE}
              control={<Radio />}
              label={`Denna ${
                isOwnBookingType ? "egna bokningen" : "tillgängligheten"
              }`}
              className={classes.radio}
            />
            <FormControlLabel
              value={updateType.FOLLOWING}
              control={<Radio />}
              label={`Denna och följande ${
                isOwnBookingType ? "egna bokningar" : "tillgängligheter"
              }`}
              className={classes.radio}
            />
          </RadioGroup>
        ) : (
          `Denna ${
            isOwnBookingType ? "egna bokningen" : "tillgänglighet"
          } kommer att raderas. Är du säker?`
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Avbryt
        </Button>
        <Button
          onClick={() =>
            isDeleteDialogType
              ? onDeleteAvailability(
                  isRecurringAvailability
                    ? availabilityUpdateType
                    : nonRecurringAvailabilityUpdateType
                )
              : onUpdateAvailability(availabilityUpdateType)
          }
          className={classes.confirmButton}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AvailabilityConfirmationDialog
