import Api from "api"

export const videoUpload = (video) => {
  const bodyFormData = new FormData()
  bodyFormData.append("video", video)

  return Api.post("users/videoUpload", bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
