import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import config from "config"
import rootReducer from "./rootReducer"

const { isDev } = config
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  isDev ? composeEnhancers(applyMiddleware(thunk)) : applyMiddleware(thunk)
)

export default store
